.MemoriesPopup-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 0.48rem;
  padding: 0.04rem;
  border-radius: 0.02rem;
  position: relative;
  overflow: hidden;
  background-color: #000000;
}
@media (max-width: 767px) {
  .MemoriesPopup-info {
    width: 0.24rem;
    padding: 0.015rem;
  }
}
.MemoriesPopup-infoImage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.MemoriesPopup-infoImage img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.MemoriesPopup-infoText {
  position: relative;
  color: #ffffff;
  font-size: 0.032rem;
  line-height: 0.9375;
  letter-spacing: -1px;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .MemoriesPopup-infoText {
    font-size: 0.016rem;
    line-height: 0.875;
  }
}
.MemoriesPopup-infoTextLink {
  color: #ff0000;
  -webkit-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
}
.isDesktop .MemoriesPopup-infoTextLink:hover {
  color: #ffffff;
}
