.AboutVideo {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 0.64rem;
  margin: -0.03rem 0;
  border-radius: 0.02rem;
  position: relative;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}
@media (max-width: 767px) {
  .AboutVideo {
    width: 0.32rem;
    margin: 0;
  }
}
.AboutVideo-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  height: 100%;
  padding: 0.03rem 0.08rem;
  position: relative;
  z-index: 10;
}
@media (max-width: 767px) {
  .AboutVideo-inner {
    padding: 0.015rem 0.03rem;
  }
}
.AboutVideo-playIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0.22rem;
  height: 0.22rem;
  margin: -0.11rem 0 0 -0.11rem;
}
@media (max-width: 767px) {
  .AboutVideo-playIcon {
    width: 0.1rem;
    height: 0.1rem;
    margin: -0.05rem 0 0 -0.05rem;
  }
}
.AboutVideo-playIcon svg path {
  -webkit-transform-origin: 50% 50%;
      -ms-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  -webkit-transform: translateX(0) scale(1);
      -ms-transform: translateX(0) scale(1);
          transform: translateX(0) scale(1);
}
@-webkit-keyframes shift-triangle {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0) scale(1);
            transform: translateX(0) scale(1);
  }
  45% {
    opacity: 0;
    -webkit-transform: translateX(100%) scale(0);
            transform: translateX(100%) scale(0);
  }
  55% {
    opacity: 0;
    -webkit-transform: translateX(-100%) scale(0);
            transform: translateX(-100%) scale(0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0) scale(1);
            transform: translateX(0) scale(1);
  }
}
@keyframes shift-triangle {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0) scale(1);
            transform: translateX(0) scale(1);
  }
  45% {
    opacity: 0;
    -webkit-transform: translateX(100%) scale(0);
            transform: translateX(100%) scale(0);
  }
  55% {
    opacity: 0;
    -webkit-transform: translateX(-100%) scale(0);
            transform: translateX(-100%) scale(0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0) scale(1);
            transform: translateX(0) scale(1);
  }
}
.isDesktop .AboutVideo-playIcon:hover svg path {
  -webkit-animation: shift-triangle 0.5s linear;
          animation: shift-triangle 0.5s linear;
}
.AboutVideo-button {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  border: 2px solid #ff0000;
  border-radius: 0.05rem;
  background-color: transparent;
  color: #ff0000;
  font-size: 0.032rem;
  line-height: 0.9375;
  letter-spacing: -1px;
  text-transform: uppercase;
  -webkit-transform-origin: 50% 100%;
      -ms-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, transform 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  padding: 0.016rem 0 0.02rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.isDesktop .AboutVideo-button:hover {
  background-color: #ff0000;
  color: #ffffff;
}
.isDesktop .AboutVideo-button:hover span {
  -webkit-animation: shift-text 0.5s linear;
          animation: shift-text 0.5s linear;
}
.isDesktop .AboutVideo-button:active {
  -webkit-transform: scale(0.9);
      -ms-transform: scale(0.9);
          transform: scale(0.9);
}
.AboutVideo-button span {
  -webkit-transform-origin: 50% 50%;
      -ms-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
          transform: translateY(0);
}
@-webkit-keyframes shift-text {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  45% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  55% {
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes shift-text {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  45% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  55% {
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@media (max-width: 767px) {
  .AboutVideo-button {
    padding: 0.005rem 0 0.007rem;
    font-size: 0.014rem;
    line-height: 1.42857143;
  }
}
.AboutVideo-buttonWrapper {
  width: 100%;
}
.AboutVideo video,
.AboutVideo img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.isTablet .AboutVideo video,
.isMobile .AboutVideo video {
  display: none;
}
.isDesktop .AboutVideo img {
  display: none;
}
