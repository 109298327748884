@media (max-width: 767px) {
  .MemoriesSection {
    padding: 0.06rem 0 0;
  }
}
.isTablet .MemoriesSection .CommonVideo-background,
.MemoriesSection .CommonVideo-background .isMobile {
  opacity: 0.4;
}
.isTablet .MemoriesSection .CommonVideo-background {
  left: -0.08rem;
  bottom: -0.3rem;
}
@media (max-width: 767px) {
  .MemoriesSection .NeedFixOnPopup {
    height: 100%;
  }
}
