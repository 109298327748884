.MemoriesPopup-chat {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  padding: 0.045rem 0.04rem 0.07rem;
  border-radius: 0.02rem;
  overflow: hidden;
  background-color: #ffffff;
  background-image: linear-gradient(#000000 1px, transparent 0), linear-gradient(90deg, #000000 1px, transparent 0);
  background-size: 0.08rem 0.08rem;
  background-position: center center;
}
@media (max-width: 767px) {
  .MemoriesPopup-chat {
    padding: 0.02rem 0.02rem 0.04rem;
    background-size: 0.04rem 0.04rem;
  }
}
.MemoriesPopup-chatItem {
  width: 0.56rem;
}
@media (max-width: 767px) {
  .MemoriesPopup-chatItem {
    width: 0.28rem;
  }
}
.MemoriesPopup-chat.isLargeSize,
.MemoriesPopup-chat.isMediumSize {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.MemoriesPopup-chat.isLargeSize .MemoriesPopup-chatItem,
.MemoriesPopup-chat.isMediumSize .MemoriesPopup-chatItem {
  margin-right: 0.08rem;
}
@media (max-width: 767px) {
  .MemoriesPopup-chat.isLargeSize .MemoriesPopup-chatItem,
  .MemoriesPopup-chat.isMediumSize .MemoriesPopup-chatItem {
    margin-right: 0.04rem;
  }
}
.MemoriesPopup-chat.isLargeSize .MemoriesPopup-chatItem:last-child,
.MemoriesPopup-chat.isMediumSize .MemoriesPopup-chatItem:last-child {
  margin-right: 0;
}
.MemoriesPopup-chat.isSmallSize {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.MemoriesPopup-chatItem {
  text-transform: uppercase;
}
.MemoriesPopup-chatItem.isRedTheme .MemoriesPopup-chatItemHeader {
  color: #ff0000;
}
.MemoriesPopup-chatItem.isRedTheme .MemoriesPopup-chatItemInner {
  background-color: #ff0000;
}
.MemoriesPopup-chatItem.isRedTheme .MemoriesPopup-chatItemInner:before {
  border-color: #ff0000 transparent transparent transparent;
}
.MemoriesPopup-chatItem.isGrayTheme .MemoriesPopup-chatItemInner {
  background-color: #7a7b7d;
}
.MemoriesPopup-chatItem.isGrayTheme .MemoriesPopup-chatItemInner:before {
  border-color: #7a7b7d transparent transparent transparent;
}
.MemoriesPopup-chatItem.isWhiteTheme .MemoriesPopup-chatItemInner {
  border: 2px solid #000000;
  background-color: #ffffff;
  color: #000000;
}
.MemoriesPopup-chatItem.isWhiteTheme .MemoriesPopup-chatItemInner:before {
  border-color: #ffffff transparent transparent transparent;
  z-index: 20;
}
.MemoriesPopup-chatItem.isWhiteTheme .MemoriesPopup-chatItemInner:after {
  left: 0.078rem;
  bottom: -0.044rem;
  border-width: 0.044rem 0.044rem 0 0;
  border-color: #000000 transparent transparent transparent;
  z-index: 10;
}
@media (max-width: 767px) {
  .MemoriesPopup-chatItem.isWhiteTheme .MemoriesPopup-chatItemInner:after {
    left: 0.068rem;
    bottom: -0.023rem;
    border-width: 0.023rem 0.024rem 0 0;
  }
}
.MemoriesPopup-chatItemHeader {
  font-size: 0.016rem;
  line-height: 1.25;
  font-weight: 700;
  margin-bottom: 0.015rem;
  color: #000000;
}
@media (max-width: 767px) {
  .MemoriesPopup-chatItemHeader {
    font-size: 0.008rem;
    line-height: 1.25;
  }
}
@media (max-width: 767px) {
  .MemoriesPopup-chatItemHeader {
    margin-bottom: 0.008rem;
  }
}
.MemoriesPopup-chatItemInner {
  padding: 0.03rem;
  border-radius: 0.02rem;
  position: relative;
  background-color: #000000;
  color: #ffffff;
  font-size: 0.03rem;
  line-height: 1;
  letter-spacing: -1px;
}
@media (max-width: 767px) {
  .MemoriesPopup-chatItemInner {
    padding: 0.02rem;
    border-radius: 0.01rem;
    font-size: 0.015rem;
    line-height: 1;
  }
}
.MemoriesPopup-chatItemInner:before,
.MemoriesPopup-chatItemInner:after {
  content: "";
  display: block;
  position: absolute;
  border-style: solid;
  border-color: transparent;
}
.MemoriesPopup-chatItemInner:before {
  left: 0.08rem;
  bottom: -0.039rem;
  border-width: 0.04rem 0.04rem 0 0;
  border-color: #000000 transparent transparent transparent;
}
@media (max-width: 767px) {
  .MemoriesPopup-chatItemInner:before {
    left: 0.07rem;
    bottom: -0.019rem;
    border-width: 0.02rem 0.02rem 0 0;
  }
}
.MemoriesPopup-chatItemText:first-letter {
  margin-right: 0.02rem;
}
@media (max-width: 767px) {
  .MemoriesPopup-chatItemText:first-letter {
    margin-right: 0.01rem;
  }
}
.MemoriesPopup-chatItemLink {
  color: #ff0000;
  -webkit-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
}
.isDesktop .MemoriesPopup-chatItemLink:hover {
  color: #ffffff;
}
.MemoriesPopup-chatItemImage {
  width: 60%;
  margin-top: 0.02rem;
}
@media (max-width: 767px) {
  .MemoriesPopup-chatItemImage {
    width: 70%;
    margin-top: 0.01rem;
  }
}
.MemoriesPopup-chatItemImage.isHeightLimit {
  height: 0.25rem;
}
@media (max-width: 767px) {
  .MemoriesPopup-chatItemImage.isHeightLimit {
    height: 0.15rem;
  }
}
.MemoriesPopup-chatItemImage img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: left;
     object-position: left;
}
.MemoriesPopup-chatItemImageZoom {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  display: none;
  width: 100vw;
  padding: 0.04rem;
  background-color: rgba(0, 0, 0, 0.95);
  z-index: 30;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
}
.isDesktop .MemoriesPopup-chatItemImageZoom {
  display: block;
}
@media (max-width: 767px) {
  .MemoriesPopup-chatItemImageZoom {
    padding: 0.01rem;
  }
}
.MemoriesPopup-chatItemImageZoom.isOpen {
  opacity: 1;
  visibility: visible;
}
.MemoriesPopup-chatItemImageZoom img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
}
.MemoriesPopup-chatItemImageZoomClose {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.isTablet .MemoriesPopup-chatItemImageZoomClose,
.isMobile .MemoriesPopup-chatItemImageZoomClose {
  top: 0.02rem;
  left: 0.02rem;
  right: inherit;
  bottom: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 0.04rem;
  height: 0.04rem;
  border: 2px solid #ff0000;
  border-radius: 100%;
}
.MemoriesPopup-chatItemImageZoomClose svg {
  display: none;
  width: 0.015rem;
  height: 0.015rem;
  fill: #ff0000;
}
.isTablet .MemoriesPopup-chatItemImageZoomClose svg,
.isMobile .MemoriesPopup-chatItemImageZoomClose svg {
  display: block;
}
.MemoriesPopup-chatItemReference {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 0.035rem;
  padding: 0.025rem 0.03rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 0.005rem;
  -webkit-transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out;
}
@media (max-width: 767px) {
  .MemoriesPopup-chatItemReference {
    margin-top: 0.015rem;
    padding: 0.01rem 0.015rem;
  }
}
.isDesktop .MemoriesPopup-chatItemReference:hover {
  border-color: #ffffff;
  background-color: #191a1b;
}
.MemoriesPopup-chatItemReferenceImage {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 0.08rem;
  margin-right: 0.03rem;
}
@media (max-width: 767px) {
  .MemoriesPopup-chatItemReferenceImage {
    width: 0.03rem;
    margin-right: 0.015rem;
  }
}
.MemoriesPopup-chatItemReferenceImage img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.MemoriesPopup-chatItemReferenceInner {
  font-size: 0.016rem;
  line-height: 1.25;
  font-weight: 700;
}
@media (max-width: 767px) {
  .MemoriesPopup-chatItemReferenceInner {
    font-size: 0.008rem;
    line-height: 1.25;
  }
}
.MemoriesPopup-chatItemReferenceTitle {
  margin-bottom: 0.015rem;
  color: #ffffff;
}
@media (max-width: 767px) {
  .MemoriesPopup-chatItemReferenceTitle {
    margin-bottom: 0.005rem;
  }
}
.MemoriesPopup-chatItemReferenceTitle:first-letter {
  margin-right: 0.01rem;
}
@media (max-width: 767px) {
  .MemoriesPopup-chatItemReferenceTitle:first-letter {
    margin-right: 0.005rem;
  }
}
.MemoriesPopup-chatItemReferenceText {
  width: 0.3rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #ff0000;
}
@media (max-width: 767px) {
  .MemoriesPopup-chatItemReferenceText {
    width: 0.13rem;
  }
}
