.MemoriesPopup-credits {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 0.51rem;
  padding: 0.04rem;
  border-radius: 0.02rem;
  position: relative;
  overflow: hidden;
  background-color: #050a0d;
}
@media (max-width: 767px) {
  .MemoriesPopup-credits {
    width: 0.32rem;
    padding: 0.015rem;
  }
}
.MemoriesPopup-creditsTitle {
  color: #ff0000;
  font-size: 0.06rem;
  line-height: 0.91666667;
  letter-spacing: -0.003rem;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .MemoriesPopup-creditsTitle {
    font-size: 0.036rem;
    line-height: 0.83333333;
    letter-spacing: -0.002rem;
  }
}
.MemoriesPopup-creditsListItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 0.016rem;
  line-height: 1.25;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .MemoriesPopup-creditsListItem {
    font-size: 0.011rem;
    line-height: 1.18181818;
  }
}
.MemoriesPopup-creditsListItemCharacter {
  width: 0.174rem;
  margin-right: 0.046rem;
  color: #ff0000;
  font-family: 'Begum', sans-serif;
}
@media (max-width: 767px) {
  .MemoriesPopup-creditsListItemCharacter {
    width: 0.13rem;
    margin-right: 0.02rem;
  }
}
.MemoriesPopup-creditsListItemVoiceds {
  width: 0.19rem;
}
@media (max-width: 767px) {
  .MemoriesPopup-creditsListItemVoiceds {
    width: auto;
  }
}
.MemoriesPopup-creditsListItemVoicedsItem {
  color: #ffffff;
}
.MemoriesPopup-creditsListTitle {
  margin: 0.035rem 0 0.02rem -0.07rem;
  color: #ffffff;
  font-size: 0.016rem;
  line-height: 1.25;
  text-transform: uppercase;
  text-align: center;
  font-family: 'Begum', sans-serif;
}
@media (max-width: 767px) {
  .MemoriesPopup-creditsListTitle {
    margin: 0.025rem 0 0.015rem -0.035rem;
    font-size: 0.011rem;
    line-height: 1.18181818;
  }
}
