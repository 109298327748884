.MemoriesPopup-exploration {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 1.6rem;
  border-radius: 0.02rem;
  position: relative;
  overflow: hidden;
  background-color: #000000;
}
@media (max-width: 767px) {
  .MemoriesPopup-exploration {
    width: 0.8rem;
  }
}
.isDesktop .MemoriesPopup-exploration.Loaded .MemoriesPopup-explorationImage {
  visibility: visible;
}
.isDesktop .MemoriesPopup-exploration.Loaded .MemoriesPopup-explorationBackground,
.isDesktop .MemoriesPopup-exploration.Loaded .MemoriesPopup-explorationLoader {
  display: none;
}
.MemoriesPopup-explorationBackground {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('/static/img/popups/5/sequence/solar-1.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.isDesktop .MemoriesPopup-explorationBackground {
  opacity: 0.2;
}
.MemoriesPopup-explorationLoader {
  position: absolute;
  top: 50%;
  left: 50%;
  display: none;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.isDesktop .MemoriesPopup-explorationLoader {
  display: block;
}
.MemoriesPopup-explorationLoaderInner {
  color: #ffffff;
  font-size: 0.016rem;
  line-height: 1.25;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
}
.MemoriesPopup-explorationLoaderSpinner {
  width: 0.08rem;
  height: 0.08rem;
  margin: 0 auto 0.024rem;
  position: relative;
}
.MemoriesPopup-explorationLoaderSpinnerLine {
  position: absolute;
  left: 0.037rem;
  bottom: 0;
  width: 0.007rem;
  height: 0.014rem;
  background-color: transparent;
  -webkit-transform-origin: center -0.025rem;
      -ms-transform-origin: center -0.025rem;
          transform-origin: center -0.025rem;
  -webkit-animation: spinner-fade 1s infinite linear;
          animation: spinner-fade 1s infinite linear;
}
.MemoriesPopup-explorationLoaderSpinnerLine:nth-child(0) {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
          transform: rotate(0deg);
}
.MemoriesPopup-explorationLoaderSpinnerLine:nth-child(1) {
  -webkit-animation-delay: 0.08s;
          animation-delay: 0.08s;
  -webkit-transform: rotate(30deg);
      -ms-transform: rotate(30deg);
          transform: rotate(30deg);
}
.MemoriesPopup-explorationLoaderSpinnerLine:nth-child(2) {
  -webkit-animation-delay: 0.16s;
          animation-delay: 0.16s;
  -webkit-transform: rotate(60deg);
      -ms-transform: rotate(60deg);
          transform: rotate(60deg);
}
.MemoriesPopup-explorationLoaderSpinnerLine:nth-child(3) {
  -webkit-animation-delay: 0.24s;
          animation-delay: 0.24s;
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
}
.MemoriesPopup-explorationLoaderSpinnerLine:nth-child(4) {
  -webkit-animation-delay: 0.32s;
          animation-delay: 0.32s;
  -webkit-transform: rotate(120deg);
      -ms-transform: rotate(120deg);
          transform: rotate(120deg);
}
.MemoriesPopup-explorationLoaderSpinnerLine:nth-child(5) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
  -webkit-transform: rotate(150deg);
      -ms-transform: rotate(150deg);
          transform: rotate(150deg);
}
.MemoriesPopup-explorationLoaderSpinnerLine:nth-child(6) {
  -webkit-animation-delay: 0.48s;
          animation-delay: 0.48s;
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}
.MemoriesPopup-explorationLoaderSpinnerLine:nth-child(7) {
  -webkit-animation-delay: 0.56s;
          animation-delay: 0.56s;
  -webkit-transform: rotate(210deg);
      -ms-transform: rotate(210deg);
          transform: rotate(210deg);
}
.MemoriesPopup-explorationLoaderSpinnerLine:nth-child(8) {
  -webkit-animation-delay: 0.64s;
          animation-delay: 0.64s;
  -webkit-transform: rotate(240deg);
      -ms-transform: rotate(240deg);
          transform: rotate(240deg);
}
.MemoriesPopup-explorationLoaderSpinnerLine:nth-child(9) {
  -webkit-animation-delay: 0.72s;
          animation-delay: 0.72s;
  -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
          transform: rotate(270deg);
}
.MemoriesPopup-explorationLoaderSpinnerLine:nth-child(10) {
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
  -webkit-transform: rotate(300deg);
      -ms-transform: rotate(300deg);
          transform: rotate(300deg);
}
.MemoriesPopup-explorationLoaderSpinnerLine:nth-child(11) {
  -webkit-animation-delay: 0.88s;
          animation-delay: 0.88s;
  -webkit-transform: rotate(330deg);
      -ms-transform: rotate(330deg);
          transform: rotate(330deg);
}
.MemoriesPopup-explorationLoaderSpinnerLine:nth-child(12) {
  -webkit-animation-delay: 0.96s;
          animation-delay: 0.96s;
  -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
          transform: rotate(360deg);
}
.MemoriesPopup-explorationLoaderSpinnerLine:nth-child(13) {
  -webkit-animation-delay: 1.04s;
          animation-delay: 1.04s;
  -webkit-transform: rotate(390deg);
      -ms-transform: rotate(390deg);
          transform: rotate(390deg);
}
.MemoriesPopup-explorationLoaderSpinnerLine:nth-child(14) {
  -webkit-animation-delay: 1.12s;
          animation-delay: 1.12s;
  -webkit-transform: rotate(420deg);
      -ms-transform: rotate(420deg);
          transform: rotate(420deg);
}
@-webkit-keyframes spinner-fade {
  0% {
    background-color: #ffffff;
  }
  100% {
    background-color: transparent;
  }
}
@keyframes spinner-fade {
  0% {
    background-color: #ffffff;
  }
  100% {
    background-color: transparent;
  }
}
.MemoriesPopup-explorationImage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0;
}
.isDesktop .MemoriesPopup-explorationImage {
  visibility: hidden;
}
.MemoriesPopup-explorationImage.isShow {
  opacity: 1;
}
.MemoriesPopup-explorationFooter {
  position: absolute;
  left: 0.04rem;
  right: 0.04rem;
  bottom: 0.04rem;
  text-transform: uppercase;
  z-index: 10;
}
@media (max-width: 767px) {
  .MemoriesPopup-explorationFooter {
    left: 0.02rem;
    right: 0.02rem;
    bottom: 0.02rem;
  }
}
.MemoriesPopup-explorationFooterInner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.MemoriesPopup-explorationTitle,
.MemoriesPopup-explorationCaption {
  font-size: 0.06rem;
  line-height: 0.91666667;
}
@media (max-width: 767px) {
  .MemoriesPopup-explorationTitle,
  .MemoriesPopup-explorationCaption {
    font-size: 0.036rem;
    line-height: 0.83333333;
    letter-spacing: -0.002rem;
  }
}
.MemoriesPopup-explorationTitle div,
.MemoriesPopup-explorationCaption div {
  display: inline-block;
}
.MemoriesPopup-explorationTitle {
  margin-bottom: -0.01rem;
  color: #ff0000;
  letter-spacing: -0.003rem;
  font-weight: 300;
  font-family: 'Begum', sans-serif;
}
@media (max-width: 767px) {
  .MemoriesPopup-explorationTitle {
    margin-bottom: -0.005rem;
  }
}
.MemoriesPopup-explorationCaption {
  color: #ffffff;
  letter-spacing: -0.004rem;
}
.MemoriesPopup-explorationFile {
  margin: 0 0 -0.002rem 0.016rem;
  color: #ffffff;
  font-size: 0.016rem;
  line-height: 1.25;
  font-weight: 700;
}
@media (max-width: 767px) {
  .MemoriesPopup-explorationFile {
    margin: 0 0 -0.002rem 0.01rem;
    font-size: 0.008rem;
    line-height: 1.25;
  }
}
