.AboutSection {
  overflow: hidden;
}
.AboutSection-inner {
  display: grid;
  grid-template-columns: 0.92rem repeat(3, 0.41rem);
  grid-gap: 0 0.2rem;
  margin-right: 0.2rem;
  padding-left: 0.2rem;
}
@media (max-width: 767px) {
  .AboutSection-inner {
    grid-template-columns: 0.4rem repeat(3, 0.25rem);
    grid-gap: 0 0.06rem;
    margin-right: 0.06rem;
    padding-left: 0.02rem;
  }
}
.AboutSection-end {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 0.02rem;
}
@media (max-width: 767px) {
  .AboutSection-end {
    width: 0.015rem;
  }
}
.AboutSection .HorizontalPage {
  padding: 0.05rem 0;
}
@media (max-width: 767px) {
  .AboutSection .HorizontalPage {
    padding: 0.08rem 0 0.02rem;
    overflow-y: hidden;
  }
}
.AboutSection .CommonVideo-background {
  opacity: 0.4;
}
