.CommonVideoPlayer.isPopupVideoPlayer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
}
.isDesktop .CommonVideoPlayer.isPopupVideoPlayer:hover .CommonVideoPlayer-controls {
  opacity: 1;
  visibility: visible;
}
.CommonVideoPlayer.isPopupVideoPlayer .CommonVideoPlayer-inner {
  height: 100%;
}
.CommonVideoPlayer.isPopupVideoPlayer .CommonVideoPlayer-cover {
  position: absolute;
  height: 100%;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.CommonVideoPlayer.isPopupVideoPlayer .CommonVideoPlayer-playIcon,
.CommonVideoPlayer.isPopupVideoPlayer .CommonVideoPlayer-pauseIcon {
  display: none;
}
.isDesktop .CommonVideoPlayer.isPopupVideoPlayer .CommonVideoPlayer-controls {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
}
.CommonVideoPlayer.isPopupVideoPlayer .CommonVideoPlayer-controlsCurrentTime,
.CommonVideoPlayer.isPopupVideoPlayer .CommonVideoPlayer-controlsDuration {
  font-size: 0.072rem;
  line-height: 0.83333333;
}
.CommonVideoPlayer-inner {
  position: relative;
}
.isDesktop .CommonVideoPlayer-inner:hover .CommonVideoPlayer-pauseIcon {
  opacity: 1;
}
.isDesktop .CommonVideoPlayer-inner:hover .CommonVideoPlayer-image:first-of-type {
  opacity: 0;
}
.isDesktop .CommonVideoPlayer-inner:hover .CommonVideoPlayer-image:last-of-type {
  opacity: 1;
}
.CommonVideoPlayer-cover {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  height: 0.52rem;
  -webkit-box-shadow: 0 20px 80px rgba(0, 0, 0, 0.5);
          box-shadow: 0 20px 80px rgba(0, 0, 0, 0.5);
}
@media (max-width: 767px) {
  .CommonVideoPlayer-cover {
    height: 0.26rem;
  }
}
.CommonVideoPlayer-playback {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.CommonVideoPlayer-playback.isPlay .CommonVideoPlayer-pauseIcon,
.CommonVideoPlayer-playback.isPause .CommonVideoPlayer-playIcon {
  opacity: 0;
  -webkit-transform: scale(0);
      -ms-transform: scale(0);
          transform: scale(0);
}
.CommonVideoPlayer-playback.isPause .CommonVideoPlayer-pauseIcon {
  opacity: 0;
}
.CommonVideoPlayer-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 1px);
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.CommonVideoPlayer-image:last-of-type {
  opacity: 0;
}
.CommonVideoPlayer-image.isHide {
  display: none;
}
.CommonVideoPlayer-playIcon,
.CommonVideoPlayer-pauseIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0.05rem;
  height: 0.05rem;
  margin: -0.025rem 0 0 -0.025rem;
  -webkit-transition: opacity 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}
@media (max-width: 767px) {
  .CommonVideoPlayer-playIcon,
  .CommonVideoPlayer-pauseIcon {
    width: 0.04rem;
    height: 0.04rem;
    margin: -0.02rem 0 0 -0.02rem;
  }
}
.CommonVideoPlayer-playIcon svg,
.CommonVideoPlayer-pauseIcon svg {
  width: 100%;
  height: 100%;
  fill: #ff0000;
}
.CommonVideoPlayer-controls {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  margin-top: 0.035rem;
}
@media (max-width: 767px) {
  .CommonVideoPlayer-controls {
    margin-top: 0.02rem;
  }
}
.CommonVideoPlayer-controlsCurrentTime,
.CommonVideoPlayer-controlsDuration {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  min-width: 0.15rem;
  padding: 0 0.02rem;
  color: #ffffff;
  font-size: 0.032rem;
  line-height: 0.9375;
  text-align: center;
}
@media (max-width: 767px) {
  .CommonVideoPlayer-controlsCurrentTime,
  .CommonVideoPlayer-controlsDuration {
    min-width: 0.1rem;
    padding: 0 0.01rem;
    font-size: 0.02rem !important;
    line-height: 0.9 !important;
  }
}
.CommonVideoPlayer-controlsTimeline {
  width: 100%;
  height: 0.01rem;
  margin-bottom: 1px;
  position: relative;
  background-color: #363a3f;
}
.isDesktop .CommonVideoPlayer-controlsTimeline:hover .CommonVideoPlayer-controlsTimelineProgress:after,
.isDesktop .CommonVideoPlayer-controlsTimeline:hover .CommonVideoPlayer-controlsTimelineLine {
  opacity: 1;
}
.CommonVideoPlayer-controlsTimelineProgress {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0;
  background-color: #ff0000;
}
.CommonVideoPlayer-controlsTimelineProgress:after {
  content: "";
  position: absolute;
  top: -0.02rem;
  right: -0.01rem;
  border-style: solid;
  border-width: 0.01rem 0.01rem 0 0.01rem;
  border-color: #ff0000 transparent transparent transparent;
  pointer-events: none;
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}
.CommonVideoPlayer-controlsTimelineLine {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 2px;
  height: 100%;
  background-color: #ffffff;
  pointer-events: none;
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}
