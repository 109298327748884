.NotFound {
  display: grid;
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  padding-left: 0.2rem;
  overflow: hidden;
}
@media (max-width: 767px) {
  .NotFound {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    padding: 0 0.02rem 0.04rem;
  }
}
.NotFound-inner {
  margin: auto 0;
}
@media (max-width: 767px) {
  .NotFound-inner {
    margin: 0;
  }
}
.NotFound-title {
  color: #ffffff;
  font-size: 0.32rem;
  line-height: 0.775;
  letter-spacing: -0.028rem;
}
@media (max-width: 767px) {
  .NotFound-title {
    font-size: 0.12rem;
    line-height: 0.91666667;
    letter-spacing: -0.01rem;
  }
}
.NotFound-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}
@media (max-width: 767px) {
  .NotFound-nav {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-bottom: -0.005rem;
  }
}
.NotFound-navItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #ffffff;
  font-size: 0.016rem;
  line-height: 1.25;
  text-transform: uppercase;
  font-weight: 700;
  -webkit-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
}
@media (max-width: 767px) {
  .NotFound-navItem {
    font-size: 0.012rem;
    line-height: 1.66666667;
  }
}
.NotFound-navItem:first-child:after {
  content: "/";
  display: block;
  margin: 0 0.005rem;
  color: #ffffff;
}
.NotFound-navItem:last-child {
  color: #ff0000;
}
.isDesktop .NotFound-navItem:last-child:hover {
  color: #ffffff;
}
.isDesktop .NotFound-navItem:hover {
  color: #ff0000;
}
