.IndexIntro {
  display: grid;
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
}
@media (max-width: 767px) {
  .IndexIntro {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }
}
.IndexIntro-inner {
  max-width: 1.3rem;
  margin: auto;
  overflow: hidden;
}
@media (max-width: 767px) {
  .IndexIntro-inner {
    margin: 0;
    padding: 0 0.02rem 0.04rem;
  }
}
.isMobile .IndexIntro-nav {
  margin-bottom: 0.06rem;
}
.IndexIntro-navItem {
  margin-bottom: 0.025rem;
}
@media (max-width: 767px) {
  .IndexIntro-navItem {
    margin-bottom: 0.005rem;
  }
}
.IndexIntro-navItem:last-child {
  margin-bottom: 0;
}
.IndexIntro-navItemText {
  margin-bottom: 0.01rem;
  color: #ff0000;
  font-size: 0.06rem;
  line-height: 0.91666667;
  letter-spacing: -0.002rem;
  text-transform: uppercase;
  font-weight: 300;
  font-family: 'Begum', sans-serif;
}
@media (max-width: 767px) {
  .IndexIntro-navItemText {
    margin-bottom: 0.005rem;
    font-size: 0.013rem;
    line-height: 1;
    letter-spacing: -1px;
  }
}
.IndexIntro-navItemLink {
  display: block;
  width: 100%;
  color: #ffffff;
  -webkit-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
}
.isDesktop .IndexIntro-navItemLink:hover {
  color: #ff0000;
}
.IndexIntro-navItemInner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.IndexIntro-navItemPeriod {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  margin-left: 0.025rem;
  color: #ffffff;
  font-size: 0.032rem;
  line-height: 0.9375;
  letter-spacing: -1px;
}
@media (max-width: 767px) {
  .IndexIntro-navItemPeriod {
    margin-left: 0.003rem;
    font-size: 0.007rem;
    line-height: 0.85714286;
  }
}
.IndexIntro-navItemPeriod:before {
  content: "";
  display: block;
  width: 0.03rem;
  height: 0.002rem;
  margin: 0 0.01rem 0.01rem 0;
  background-color: #ffffff;
}
@media (max-width: 767px) {
  .IndexIntro-navItemPeriod:before {
    width: 0.007rem;
    height: 1px;
    margin: 0 0.002rem 0.002rem 0;
  }
}
.IndexIntro-navItem svg {
  fill: currentColor;
}
.IndexIntro-alert {
  display: none;
  padding: 0.015rem 0.02rem;
  border-radius: 0.005rem;
  position: relative;
  background-color: #ff0000;
}
.isMobile .IndexIntro-alert {
  display: block;
}
.IndexIntro-alert:before {
  content: "";
  display: block;
  position: absolute;
  right: -0.009rem;
  bottom: 0.01rem;
  border-style: solid;
  border-width: 0.01rem 0 0 0.01rem;
  border-color: transparent transparent transparent #ff0000;
}
.IndexIntro-alertText {
  color: #000000;
  font-size: 0.01rem;
  line-height: 1.6;
  text-transform: uppercase;
  font-weight: 700;
}
.IndexIntro-alertTextDecor {
  border-bottom: 1px solid #000000;
}
