.MemoriesPopup-later {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 0.48rem;
  padding: 0.045rem 0.04rem;
  border-radius: 0.02rem;
  background-color: #000000;
  text-transform: uppercase;
}
.MemoriesPopup-laterTitle {
  color: #ff0000;
  font-size: 0.06rem;
  line-height: 0.91666667;
  letter-spacing: -0.003rem;
}
@media (max-width: 767px) {
  .MemoriesPopup-laterTitle {
    font-size: 0.036rem;
    line-height: 0.83333333;
    letter-spacing: -0.002rem;
  }
}
.MemoriesPopup-laterText {
  color: #ffffff;
  font-size: 0.03rem;
  line-height: 1;
  letter-spacing: -1px;
}
@media (max-width: 767px) {
  .MemoriesPopup-laterText {
    font-size: 0.015rem;
    line-height: 1;
  }
}
