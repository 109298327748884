.MemoriesPopup-intro {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 0.78rem;
  padding: 0.197rem 0.076rem 0.046rem 0.18rem;
  border-radius: 0.02rem;
  position: relative;
  background-color: #ff0000;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .MemoriesPopup-intro {
    width: 0.39rem;
    padding: 0.135rem 0.038rem 0.038rem 0.09rem;
  }
}
.MemoriesPopup-introTitle {
  position: absolute;
  top: 0.08rem;
  left: 0.18rem;
  z-index: 10;
  color: #000000;
  font-size: 0.2rem;
  letter-spacing: -0.008rem;
  white-space: nowrap;
  font-weight: 300;
  font-family: 'Begum', sans-serif;
}
@media (max-width: 767px) {
  .MemoriesPopup-introTitle {
    top: 0.08rem;
    left: 0.09rem;
    font-size: 0.1rem;
    letter-spacing: -0.004rem;
  }
}
.MemoriesPopup-introHeader {
  margin-left: 0.005rem;
  position: relative;
  z-index: 20;
  color: #ffffff;
  font-size: 0.06rem;
  line-height: 0.91666667;
  letter-spacing: -0.004rem;
}
@media (max-width: 767px) {
  .MemoriesPopup-introHeader {
    font-size: 0.036rem;
    line-height: 0.83333333;
    letter-spacing: -0.002rem;
  }
}
.MemoriesPopup-introNumber {
  margin-left: 0.135rem;
}
.MemoriesPopup-introSize {
  margin-top: -0.005rem;
  white-space: nowrap;
}
@media (max-width: 767px) {
  .MemoriesPopup-introSize {
    margin-top: 0;
  }
}
.MemoriesPopup-introFooter {
  color: #ffffff;
  font-size: 0.016rem;
  line-height: 1.25;
  font-weight: 700;
}
@media (max-width: 767px) {
  .MemoriesPopup-introFooter {
    font-size: 0.008rem;
    line-height: 1.25;
  }
}
.MemoriesPopup-introDescription {
  margin: 0 0 0.059rem -0.08rem;
}
@media (max-width: 767px) {
  .MemoriesPopup-introDescription {
    margin: 0 0 0.03rem -0.04rem;
  }
}
.MemoriesPopup-introText {
  width: 0.4rem;
  color: #000000;
  font-weight: 400;
  font-family: 'Begum', sans-serif;
}
@media (max-width: 767px) {
  .MemoriesPopup-introText {
    width: 0.2rem;
  }
}
.MemoriesPopup-introText:first-letter {
  margin-right: 0.03rem;
}
@media (max-width: 767px) {
  .MemoriesPopup-introText:first-letter {
    margin-right: 0.02rem;
  }
}
