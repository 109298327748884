.u-pressText {
  font-size: 0.016rem;
  line-height: 1.25;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .u-pressText {
    font-size: 0.008rem;
    line-height: 1.875;
  }
}
.AboutPress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 767px) {
  .AboutPress {
    padding-bottom: 0.015rem;
  }
}
.AboutPress-title {
  margin-bottom: 0.04rem;
  color: #ff0000;
  font-size: 0.072rem;
  line-height: 0.83333333;
  letter-spacing: -0.003rem;
  text-transform: uppercase;
  font-weight: 300;
  font-family: 'Begum', sans-serif;
}
@media (max-width: 767px) {
  .AboutPress-title {
    margin-bottom: 0.01rem;
    font-size: 0.025rem;
    line-height: 1.2;
  }
}
.AboutPress-text {
  font-size: 0.016rem;
  line-height: 1.25;
  text-transform: uppercase;
  color: #ffffff;
}
@media (max-width: 767px) {
  .AboutPress-text {
    font-size: 0.008rem;
    line-height: 1.875;
  }
}
.AboutPress-link {
  font-size: 0.016rem;
  line-height: 1.25;
  text-transform: uppercase;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: #ff0000;
  -webkit-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
}
@media (max-width: 767px) {
  .AboutPress-link {
    font-size: 0.008rem;
    line-height: 1.875;
  }
}
.isDesktop .AboutPress-link:hover {
  color: #ffffff;
}
.AboutPress-image {
  height: 0.18rem;
  margin: 0.035rem 0 0.025rem;
}
@media (max-width: 767px) {
  .AboutPress-image {
    height: 0.12rem;
    margin: 0.015rem 0 0.01rem;
  }
}
.AboutPress-image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.AboutPress-buttons {
  margin-top: 0.03rem;
}
@media (max-width: 767px) {
  .AboutPress-buttons {
    margin-top: 0.02rem;
  }
}
.AboutPress-buttonsItem {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  border: 2px solid #ff0000;
  border-radius: 0.05rem;
  background-color: transparent;
  color: #ff0000;
  font-size: 0.032rem;
  line-height: 0.9375;
  letter-spacing: -1px;
  text-transform: uppercase;
  -webkit-transform-origin: 50% 100%;
      -ms-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, transform 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  padding: 0.016rem 0 0.02rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.isDesktop .AboutPress-buttonsItem:hover {
  background-color: #ff0000;
  color: #ffffff;
}
.isDesktop .AboutPress-buttonsItem:hover span {
  -webkit-animation: shift-text 0.5s linear;
          animation: shift-text 0.5s linear;
}
.isDesktop .AboutPress-buttonsItem:active {
  -webkit-transform: scale(0.9);
      -ms-transform: scale(0.9);
          transform: scale(0.9);
}
.AboutPress-buttonsItem span {
  -webkit-transform-origin: 50% 50%;
      -ms-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
          transform: translateY(0);
}
@-webkit-keyframes shift-text {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  45% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  55% {
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes shift-text {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  45% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  55% {
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@media (max-width: 767px) {
  .AboutPress-buttonsItem {
    padding: 0.005rem 0 0.007rem;
    font-size: 0.014rem;
    line-height: 1.42857143;
  }
}
.AboutPress-buttonsItemWrapper:nth-child(even) {
  margin-top: 0.04rem;
}
@media (max-width: 767px) {
  .AboutPress-buttonsItemWrapper:nth-child(even) {
    margin-top: 0.01rem;
  }
}
