.MemoriesSlider {
  position: fixed;
  left: 0;
  right: 0;
  display: none;
  height: calc(100% - 50px);
  -webkit-transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
}
@media (max-width: 767px) {
  .MemoriesSlider {
    display: block;
  }
}
.MemoriesSlider.isHidden {
  opacity: 0;
  visibility: hidden;
}
.MemoriesSlider-inner {
  height: 100%;
  padding: 0.02rem 0 0.025rem !important;
  overflow: inherit !important;
}
.MemoriesSlider-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  -webkit-transition: opacity 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
}
.MemoriesSlider-itemWrapper {
  padding: 0 0.015rem;
}
.MemoriesSlider-itemWrapper.swiper-slide-prev .MemoriesSlider-item {
  -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
          transform: scale(1.1);
}
.MemoriesSlider-itemWrapper.swiper-slide-next .MemoriesSlider-item {
  -webkit-transform: scale(0.9);
      -ms-transform: scale(0.9);
          transform: scale(0.9);
}
.MemoriesSlider-itemWrapper.swiper-slide-active .MemoriesSlider-item {
  pointer-events: all;
  opacity: 1;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
}
.MemoriesSlider-itemWrapper.swiper-slide-active .MemoriesSlider-itemImage img {
  -webkit-transition-delay: 0.5s;
          transition-delay: 0.5s;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
}
.MemoriesSlider-itemImage {
  height: 100%;
  border-radius: 0.02rem;
  overflow: hidden;
  -webkit-box-shadow: 0 5px 20px rgba(255, 0, 0, 0.2);
          box-shadow: 0 5px 20px rgba(255, 0, 0, 0.2);
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
}
.MemoriesSlider-itemImage img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-transition: -webkit-transform 1s ease-in-out;
  transition: -webkit-transform 1s ease-in-out;
  transition: transform 1s ease-in-out;
  transition: transform 1s ease-in-out, -webkit-transform 1s ease-in-out;
}
.MemoriesSlider-itemContent {
  margin-top: -0.02rem;
  padding: 0 0.02rem;
  position: relative;
  text-transform: uppercase;
}
.MemoriesSlider-itemCaption {
  color: #ffffff;
  font-size: 0.032rem;
  line-height: 0.9375;
  letter-spacing: -0.002rem;
  margin-bottom: 0.01rem;
}
.MemoriesSlider-itemFooter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.MemoriesSlider-itemHint {
  margin: 0 0.01rem 0.002rem 0.003rem;
  color: #ffffff;
  font-size: 0.01rem;
  line-height: 2;
  white-space: nowrap;
  font-weight: 700;
  -webkit-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
}
.MemoriesSlider-itemTitle {
  color: #ff0000;
  font-size: 0.032rem;
  line-height: 0.9375;
  letter-spacing: -0.002rem;
  font-family: 'Begum', sans-serif;
}
.MemoriesSlider .swiper-wrapper {
  height: calc(100% - 60px);
}
.MemoriesSlider .swiper-pagination {
  bottom: 0.05rem !important;
}
.MemoriesSlider .swiper-pagination-bullet {
  display: inline-block;
  width: 0.004rem;
  height: 0.004rem;
  margin: 0 0.006rem !important;
  border: 1px solid #ffffff;
  border-radius: 100%;
  opacity: 1;
  -webkit-transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out, transform 0.2s ease-in-out;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out, transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}
.MemoriesSlider .swiper-pagination-bullet-active {
  border-color: #ff0000;
  background-color: #ff0000 !important;
  -webkit-transform: scale(1.5);
      -ms-transform: scale(1.5);
          transform: scale(1.5);
}
.MemoriesSlider-hint {
  position: absolute;
  top: 50%;
  right: 0.01rem;
  width: 0.03rem;
  height: 0.026rem;
  margin-top: -0.052rem;
  z-index: 10;
  -webkit-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
}
.MemoriesSlider-hint.isHidden {
  opacity: 0;
  visibility: hidden;
}
.MemoriesSlider-hint svg {
  width: 100%;
  height: 100%;
  fill: #ff0000;
}
