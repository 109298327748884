.MemoriesPopup-note {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 1.12rem;
  padding: 0.04rem;
  border-radius: 0.02rem;
  background-color: #000000;
}
@media (max-width: 767px) {
  .MemoriesPopup-note {
    width: 0.56rem;
    padding: 0.02rem;
  }
}
.MemoriesPopup-noteQuestion,
.MemoriesPopup-noteAnswer {
  color: #ffffff;
  font-size: 0.06rem;
  line-height: 0.91666667;
  letter-spacing: -0.003rem;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .MemoriesPopup-noteQuestion,
  .MemoriesPopup-noteAnswer {
    font-size: 0.036rem;
    line-height: 0.83333333;
    letter-spacing: -0.002rem;
  }
}
.MemoriesPopup-noteQuestion:before,
.MemoriesPopup-noteAnswer:before {
  content: "";
  display: block;
  float: left;
  width: 0.06rem;
  height: 0.06rem;
  border-radius: 100%;
}
@media (max-width: 767px) {
  .MemoriesPopup-noteQuestion:before,
  .MemoriesPopup-noteAnswer:before {
    display: none;
  }
}
.MemoriesPopup-noteQuestion {
  font-weight: 300;
  font-family: 'Begum', sans-serif;
}
.MemoriesPopup-noteQuestion:before {
  margin: -0.015rem 0.06rem 0 0;
  background-color: #ff0000;
}
.MemoriesPopup-noteAnswer:before {
  margin: -0.006rem 0.06rem 0 0;
  background-color: #ffffff;
}
