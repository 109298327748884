.CommonAudioPlayer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 0.047rem;
}
@media (max-width: 767px) {
  .CommonAudioPlayer {
    margin-top: 0.015rem;
  }
}
.CommonAudioPlayer.isPlaying .CommonAudioPlayer-controls {
  background-color: transparent;
}
.isDesktop .CommonAudioPlayer.isPlaying .CommonAudioPlayer-controls:hover {
  background-color: #ffffff;
}
.CommonAudioPlayer.isPlaying .CommonAudioPlayer-controlsButton svg:first-child {
  opacity: 0;
}
.CommonAudioPlayer.isPlaying .CommonAudioPlayer-controlsButton svg:last-child {
  opacity: 1;
}
.CommonAudioPlayer.isPlaying .CommonAudioPlayer-controlsProgressSubstrate {
  stroke: #ffffff;
}
.CommonAudioPlayer-sound {
  display: none;
}
.CommonAudioPlayer-controls {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 0.16rem;
  height: 0.16rem;
  border-radius: 100%;
  background-color: #ff0000;
  position: relative;
  -webkit-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
}
@media (max-width: 767px) {
  .CommonAudioPlayer-controls {
    width: 0.06rem;
    height: 0.06rem;
  }
}
.isDesktop .CommonAudioPlayer-controls:hover {
  background-color: transparent;
}
.CommonAudioPlayer-controls:active .CommonAudioPlayer-controlsButton {
  -webkit-transform: scale(0.9);
      -ms-transform: scale(0.9);
          transform: scale(0.9);
}
.CommonAudioPlayer-controlsButton {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 20;
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}
.CommonAudioPlayer-controlsButton svg {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}
.CommonAudioPlayer-controlsButton svg:first-child {
  width: 0.07rem;
  height: 0.07rem;
  margin-left: 0.005rem;
}
@media (max-width: 767px) {
  .CommonAudioPlayer-controlsButton svg:first-child {
    width: 0.03rem;
    height: 0.03rem;
    margin-left: 0.003rem;
  }
}
.CommonAudioPlayer-controlsButton svg:last-child {
  width: 0.05rem;
  height: 0.05rem;
  opacity: 0;
}
@media (max-width: 767px) {
  .CommonAudioPlayer-controlsButton svg:last-child {
    width: 0.02rem;
    height: 0.02rem;
  }
}
.CommonAudioPlayer-controlsProgress,
.CommonAudioPlayer-controlsProgressSubstrate {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 100%;
}
.CommonAudioPlayer-controlsProgress path,
.CommonAudioPlayer-controlsProgressSubstrate path {
  stroke-width: 10;
}
.CommonAudioPlayer-controlsProgress {
  z-index: 10;
  stroke: #ff0000;
}
.CommonAudioPlayer-controlsProgressSubstrate {
  stroke: #ff0000;
  -webkit-transition: stroke 0.2s ease-in-out;
  transition: stroke 0.2s ease-in-out;
}
.CommonAudioPlayer-file {
  width: 0.3rem;
  margin-left: 0.02rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #ffffff;
  font-size: 0.016rem;
  line-height: 1.25;
  letter-spacing: 0;
  font-weight: 700;
}
@media (max-width: 767px) {
  .CommonAudioPlayer-file {
    width: 0.13rem;
    margin-left: 0.01rem;
    font-size: 0.008rem;
    line-height: 1.25;
  }
}
