.MemoriesNav.isSpiral {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.MemoriesNav.isSpiral .MemoriesNav-item {
  height: 100%;
}
.isDesktop .MemoriesNav.isSpiral .MemoriesNav-item:hover .MemoriesNav-itemContent {
  -webkit-filter: brightness(100%) !important;
          filter: brightness(100%) !important;
  -webkit-transition: -webkit-filter 0.2s ease-in-out;
  transition: -webkit-filter 0.2s ease-in-out;
  transition: filter 0.2s ease-in-out;
  transition: filter 0.2s ease-in-out, -webkit-filter 0.2s ease-in-out;
}
.isDesktop .MemoriesNav.isSpiral .MemoriesNav-item:hover .MemoriesNav-itemOverlay {
  opacity: 0 !important;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}
.MemoriesNav.isSpiral .MemoriesNav-itemWrapper {
  position: absolute;
  top: 48.5vh;
  left: 85.5vw;
  width: 880px;
  height: 480px;
  margin: -240px 0 0 -440px;
  will-change: opacity, transform;
  -webkit-transform-origin: 50% 50%;
      -ms-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  -webkit-transition: -webkit-filter 0.4s ease-in-out;
  transition: -webkit-filter 0.4s ease-in-out;
  transition: filter 0.4s ease-in-out;
  transition: filter 0.4s ease-in-out, -webkit-filter 0.4s ease-in-out;
}
.MemoriesNav.isSpiral .MemoriesNav-itemWrapper:nth-child(18) {
  z-index: 1;
}
.MemoriesNav.isSpiral .MemoriesNav-itemWrapper:nth-child(17) {
  z-index: 2;
}
.MemoriesNav.isSpiral .MemoriesNav-itemWrapper:nth-child(16) {
  z-index: 3;
}
.MemoriesNav.isSpiral .MemoriesNav-itemWrapper:nth-child(15) {
  z-index: 4;
}
.MemoriesNav.isSpiral .MemoriesNav-itemWrapper:nth-child(14) {
  z-index: 5;
}
.MemoriesNav.isSpiral .MemoriesNav-itemWrapper:nth-child(13) {
  z-index: 6;
}
.MemoriesNav.isSpiral .MemoriesNav-itemWrapper:nth-child(12) {
  z-index: 7;
}
.MemoriesNav.isSpiral .MemoriesNav-itemWrapper:nth-child(11) {
  z-index: 8;
}
.MemoriesNav.isSpiral .MemoriesNav-itemWrapper:nth-child(10) {
  z-index: 9;
}
.MemoriesNav.isSpiral .MemoriesNav-itemWrapper:nth-child(9) {
  z-index: 10;
}
.MemoriesNav.isSpiral .MemoriesNav-itemWrapper:nth-child(8) {
  z-index: 11;
}
.MemoriesNav.isSpiral .MemoriesNav-itemWrapper:nth-child(7) {
  z-index: 12;
}
.MemoriesNav.isSpiral .MemoriesNav-itemWrapper:nth-child(6) {
  z-index: 13;
}
.MemoriesNav.isSpiral .MemoriesNav-itemWrapper:nth-child(5) {
  z-index: 14;
}
.MemoriesNav.isSpiral .MemoriesNav-itemWrapper:nth-child(4) {
  z-index: 15;
}
.MemoriesNav.isSpiral .MemoriesNav-itemWrapper:nth-child(3) {
  z-index: 16;
}
.MemoriesNav.isSpiral .MemoriesNav-itemWrapper:nth-child(2) {
  z-index: 17;
}
.MemoriesNav.isSpiral .MemoriesNav-itemWrapper:nth-child(1) {
  z-index: 18;
}
.disabledHover .MemoriesNav.isSpiral .MemoriesNav-itemWrapper {
  pointer-events: none;
}
.MemoriesNav.isSpiral .MemoriesNav-itemInner:before {
  -webkit-box-shadow: 0 10px 40px rgba(255, 0, 0, 0.2);
          box-shadow: 0 10px 40px rgba(255, 0, 0, 0.2);
}
.MemoriesNav.isSpiral .MemoriesNav-itemInner:after {
  -webkit-box-shadow: 0 20px 60px rgba(255, 0, 0, 0.5);
          box-shadow: 0 20px 60px rgba(255, 0, 0, 0.5);
}
.MemoriesNav.isSpiral .MemoriesNav-itemContent {
  position: absolute;
  left: 0.08rem;
  bottom: -0.1rem;
  z-index: 10;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
}
.MemoriesNav.isSpiral .MemoriesNav-itemCaption {
  color: #ffffff;
  font-size: 0.072rem;
  line-height: 1;
  letter-spacing: -0.004rem;
  margin-bottom: 0.005rem;
}
.MemoriesNav.isSpiral .MemoriesNav-itemHint {
  color: #ffffff;
  font-size: 0.016rem;
  line-height: 1.25;
  font-weight: 700;
  -webkit-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
  margin: 0 0.07rem 0.015rem 0;
}
.MemoriesNav.isSpiral .MemoriesNav-itemTitle {
  color: #ff0000;
  font-size: 0.072rem;
  line-height: 1;
  letter-spacing: -0.004rem;
}
.MemoriesNav.isSpiral .MemoriesNav-itemOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #080a14;
  will-change: opacity;
}
.MemoriesNav.isSpiral .MemoriesNav-control {
  opacity: 1;
  visibility: visible;
}
.MemoriesNav.isSpiral .MemoriesNav-control.isHidden {
  opacity: 0;
  visibility: hidden;
}
.MemoriesNav.isGrid {
  display: grid;
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
}
.MemoriesNav.isGrid .MemoriesNav-inner {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0.03rem;
  width: 1.52rem;
  margin: auto;
  padding: 0.02rem;
}
.MemoriesNav.isGrid .MemoriesNav-item {
  height: 0.26rem;
}
.MemoriesNav.isGrid .MemoriesNav-itemWrapper {
  display: block !important;
  opacity: 1 !important;
  pointer-events: all !important;
  -webkit-filter: blur(0) !important;
          filter: blur(0) !important;
  -webkit-transform: rotate(0) translate(0) scale(1) rotate(0) !important;
      -ms-transform: rotate(0) translate(0) scale(1) rotate(0) !important;
          transform: rotate(0) translate(0) scale(1) rotate(0) !important;
}
.MemoriesNav.isGrid .MemoriesNav-itemWrapper.isCloned {
  display: none !important;
}
.MemoriesNav.isGrid .MemoriesNav-itemInner {
  padding: 0.02rem;
}
.MemoriesNav.isGrid .MemoriesNav-itemInner:before {
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
          box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
}
.MemoriesNav.isGrid .MemoriesNav-itemInner:after {
  -webkit-box-shadow: 0 5px 20px rgba(255, 0, 0, 0.5);
          box-shadow: 0 5px 20px rgba(255, 0, 0, 0.5);
}
.MemoriesNav.isGrid .MemoriesNav-itemContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  height: 100%;
  -webkit-filter: brightness(100%) !important;
          filter: brightness(100%) !important;
}
.MemoriesNav.isGrid .MemoriesNav-itemCaption {
  color: #ffffff;
  font-size: 0.032rem;
  line-height: 0.9375;
  letter-spacing: -0.002rem;
  margin-bottom: 0.007rem;
}
.MemoriesNav.isGrid .MemoriesNav-itemHint {
  color: #ffffff;
  font-size: 0.01rem;
  line-height: 2;
  font-weight: 700;
  -webkit-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
  margin-right: 0.005rem;
}
.MemoriesNav.isGrid .MemoriesNav-itemTitle {
  color: #ff0000;
  font-size: 0.032rem;
  line-height: 0.9375;
  letter-spacing: -0.002rem;
}
@media (max-width: 767px) {
  .MemoriesNav {
    display: none !important;
  }
}
.MemoriesNav-item {
  display: block;
  text-transform: uppercase;
}
.isDesktop .MemoriesNav-item:hover .MemoriesNav-itemInner:before,
.isDesktop .MemoriesNav-item:hover .MemoriesNav-itemInner:after {
  will-change: opacity;
}
.isDesktop .MemoriesNav-item:hover .MemoriesNav-itemInner:before {
  opacity: 0;
}
.isDesktop .MemoriesNav-item:hover .MemoriesNav-itemInner:after {
  opacity: 1;
}
.isDesktop .MemoriesNav-item:hover .MemoriesNav-itemHint {
  color: #ff0000;
}
.isDesktop .MemoriesNav-item:active .MemoriesNav-itemInner {
  -webkit-transform: scale(0.97);
      -ms-transform: scale(0.97);
          transform: scale(0.97);
}
.isDesktop .MemoriesNav-item.onHover .MemoriesNav-itemVideo video {
  opacity: 1;
  visibility: visible;
  -webkit-transition: none;
  transition: none;
}
.isDesktop .MemoriesNav-item.onHover .MemoriesNav-itemImage {
  opacity: 0;
  -webkit-transition: none;
  transition: none;
}
.MemoriesNav-itemInner {
  height: 100%;
  position: relative;
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}
.MemoriesNav-itemInner:before,
.MemoriesNav-itemInner:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0.02rem;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}
.MemoriesNav-itemInner:after {
  opacity: 0;
}
.MemoriesNav-itemVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0.02rem;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}
.MemoriesNav-itemVideo video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  -webkit-transition: all 0s cubic-bezier(0.165, 0.84, 0.44, 1) 350ms;
  transition: all 0s cubic-bezier(0.165, 0.84, 0.44, 1) 350ms;
}
.MemoriesNav-itemImage {
  height: 100%;
  pointer-events: none;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-transition: opacity 350ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: opacity 350ms cubic-bezier(0.165, 0.84, 0.44, 1);
}
.MemoriesNav-itemFooter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.MemoriesNav-itemTitle {
  font-family: 'Begum', sans-serif;
}
.MemoriesNav-control {
  position: absolute;
  left: 50%;
  bottom: 0.08rem;
  display: none;
  z-index: 50;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  -webkit-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
}
.isTablet .MemoriesNav-control {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.MemoriesNav-controlPrev,
.MemoriesNav-controlNext {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 0.07rem;
  height: 0.07rem;
  border: 2px solid #ff0000;
  border-radius: 100%;
  position: relative;
}
.MemoriesNav-controlPrev:before,
.MemoriesNav-controlNext:before {
  content: "";
  display: block;
  position: absolute;
  top: -0.02rem;
  left: -0.02rem;
  right: -0.02rem;
  bottom: -0.02rem;
  border-radius: 100%;
}
.MemoriesNav-controlPrev {
  margin-right: 0.03rem;
}
.MemoriesNav-controlPrev svg {
  -webkit-transform: rotate(-180deg);
      -ms-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.MemoriesNav-control svg {
  width: 0.03rem;
  height: 0.03rem;
  fill: #ff0000;
}
