.Header {
  position: fixed;
  top: 0.04rem;
  left: 0.04rem;
  bottom: 0.04rem;
  z-index: 1000;
}
@media (max-width: 767px) {
  .Header {
    top: 0.02rem;
    left: 0;
    right: 0;
    bottom: inherit;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    z-index: 10;
  }
}
.Header.isDarkTheme .Header-menuItem {
  border-color: #000000;
  background-color: #ff0000;
  color: #000000;
}
.isDesktop .Header.isDarkTheme .Header-menuItem:hover {
  background-color: #000000;
  color: #ffffff;
}
.IndexPage .Header.isDarkTheme .Header-menuItem[data-id="index"],
.MemoriesPage .Header.isDarkTheme .Header-menuItem[data-id="memories"],
.AboutPage .Header.isDarkTheme .Header-menuItem[data-id="about"] {
  background-color: #000000;
  color: #ffffff;
}
.Header-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 100%;
}
@media (max-width: 767px) {
  .Header-menu {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    width: 100%;
  }
}
.Header-menuWrapper {
  height: 100%;
}
@media (min-width: 768px) {
  .Header-menuWrapper {
    opacity: 1 !important;
    visibility: visible !important;
  }
}
@media (max-width: 767px) {
  .Header-menuWrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0.02rem 0.015rem;
    background-color: #141017;
    opacity: 0;
    visibility: hidden;
  }
}
.Header-menuItem {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 120px;
  border: 1px solid #ffffff;
  border-radius: 0.05rem;
  color: #ffffff;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  -webkit-writing-mode: vertical-lr;
      -ms-writing-mode: tb-lr;
          writing-mode: vertical-lr;
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
  -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}
@media (max-width: 767px) {
  .Header-menuItem {
    width: 100%;
    height: auto;
    border: none;
    border-radius: 0;
    position: relative;
    font-size: 0.058rem;
    line-height: 1.03448276;
    letter-spacing: -0.004rem;
    text-align: left;
    -webkit-writing-mode: horizontal-tb;
        -ms-writing-mode: lr-tb;
            writing-mode: horizontal-tb;
    -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
            transform: rotate(0);
  }
}
.Header-menuItem:nth-child(2) {
  margin: 0.04rem 0;
}
@media (max-width: 767px) {
  .Header-menuItem:nth-child(2) {
    margin: 0;
  }
}
.isDesktop .Header-menuItem:hover {
  background-color: #ffffff;
  color: #000000;
}
@media (max-width: 767px) {
  .isDesktop .Header-menuItem:hover {
    background-color: transparent;
    color: #ff0000;
  }
}
.IndexPage .Header-menuItem[data-id="index"],
.MemoriesPage .Header-menuItem[data-id="memories"],
.AboutPage .Header-menuItem[data-id="about"] {
  background-color: #ffffff;
  color: #000000;
  pointer-events: none;
}
@media (max-width: 767px) {
  .IndexPage .Header-menuItem[data-id="index"],
  .MemoriesPage .Header-menuItem[data-id="memories"],
  .AboutPage .Header-menuItem[data-id="about"] {
    background-color: transparent;
    color: #ff0000;
  }
}
.Header-menuItemSeparator {
  position: absolute;
  left: 0;
  bottom: 0;
  display: none;
  width: 0;
  height: 1px;
  background-color: #2d2d2d;
}
@media (max-width: 767px) {
  .Header-menuItemSeparator {
    display: block;
  }
}
.Header-menuButton {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  border: 2px solid #ff0000;
  border-radius: 0.05rem;
  background-color: transparent;
  color: #ff0000;
  font-size: 0.032rem;
  line-height: 0.9375;
  letter-spacing: -1px;
  text-transform: uppercase;
  -webkit-transform-origin: 50% 100%;
      -ms-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, transform 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  padding: 0.012rem 0 0.016rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.isDesktop .Header-menuButton:hover {
  background-color: #ff0000;
  color: #ffffff;
}
.isDesktop .Header-menuButton:hover span {
  -webkit-animation: shift-text 0.5s linear;
          animation: shift-text 0.5s linear;
}
.isDesktop .Header-menuButton:active {
  -webkit-transform: scale(0.9);
      -ms-transform: scale(0.9);
          transform: scale(0.9);
}
.Header-menuButton span {
  -webkit-transform-origin: 50% 50%;
      -ms-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
          transform: translateY(0);
}
@-webkit-keyframes shift-text {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  45% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  55% {
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes shift-text {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  45% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  55% {
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
.Header-menuButtonWrapper {
  display: none;
  width: 100%;
}
@media (max-width: 767px) {
  .Header-menuButtonWrapper {
    display: block;
  }
}
.Header-openMenu {
  display: none;
  padding: 0.001rem 0.02rem 0.003rem;
  border: 2px solid #ffffff;
  border-radius: 0.05rem;
  background-color: transparent;
  color: #ffffff;
  font-size: 0.012rem;
  line-height: 1.66666667;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .Header-openMenu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.Header-closeMenu {
  display: none;
  margin-bottom: 0.02rem;
}
@media (max-width: 767px) {
  .Header-closeMenu {
    display: block;
  }
}
.Header-closeMenu svg {
  width: 0.032rem;
  height: 0.032rem;
  fill: #ffffff;
}
