.MemoriesPopup-next {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 0.96rem;
  padding: 0 0.04rem;
}
@media (max-width: 767px) {
  .MemoriesPopup-next {
    width: 0.5rem;
    padding: 0 0.02rem;
  }
}
.MemoriesPopup-nextCard {
  display: block;
  width: 100%;
  height: 0.48rem;
  position: relative;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .MemoriesPopup-nextCard {
    height: 0.24rem;
  }
}
.isDesktop .MemoriesPopup-nextCard:hover:before {
  opacity: 1;
}
.isDesktop .MemoriesPopup-nextCard:hover .MemoriesPopup-next-nextCardHint {
  color: #ff0000;
}
.MemoriesPopup-nextCard:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0.02rem;
  -webkit-box-shadow: 0 20px 60px rgba(255, 0, 0, 0.5);
          box-shadow: 0 20px 60px rgba(255, 0, 0, 0.5);
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}
.MemoriesPopup-nextCardImage {
  height: 100%;
  border-radius: 0.02rem;
  overflow: hidden;
}
.MemoriesPopup-nextCardImage img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.MemoriesPopup-nextCardInner {
  position: absolute;
  left: 0.08rem;
  bottom: -0.09rem;
}
@media (max-width: 767px) {
  .MemoriesPopup-nextCardInner {
    left: 0.04rem;
    bottom: -0.05rem;
  }
}
.MemoriesPopup-nextCardCaption,
.MemoriesPopup-nextCardTitle {
  color: #ffffff;
  font-size: 0.06rem;
  line-height: 0.91666667;
  letter-spacing: -0.004rem;
}
@media (max-width: 767px) {
  .MemoriesPopup-nextCardCaption,
  .MemoriesPopup-nextCardTitle {
    font-size: 0.036rem;
    line-height: 0.83333333;
    letter-spacing: -0.002rem;
  }
}
.MemoriesPopup-nextCardCaption {
  margin-bottom: 0.01rem;
}
.MemoriesPopup-nextCardFooter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.MemoriesPopup-nextCardHint {
  margin: 0 0.07rem 0.01rem 0.005rem;
  color: #ffffff;
  font-size: 0.016rem;
  line-height: 1.25;
  font-weight: 700;
  -webkit-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
}
@media (max-width: 767px) {
  .MemoriesPopup-nextCardHint {
    margin: 0 0.02rem 0.005rem 0;
    font-size: 0.008rem;
    line-height: 1.25;
  }
}
.MemoriesPopup-nextCardTitle {
  color: #ff0000;
  letter-spacing: -0.003rem;
  font-family: 'Begum', sans-serif;
}
