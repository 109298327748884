.CommonVideoPopup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.14rem 0 0.05rem;
  background-color: #000000;
  z-index: 2000;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
  --plyr-color-main: #ff0000;
}
@media (max-width: 767px) {
  .CommonVideoPopup {
    padding: 0.06rem 0 0.03rem;
  }
}
.CommonVideoPopup.isOpen {
  opacity: 1;
  visibility: visible;
}
.CommonVideoPopup.isOpen .CommonVideoPopup-inner {
  visibility: visible;
}
.CommonVideoPopup-close {
  position: absolute;
  top: 0.035rem;
  right: 0.035rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 0.06rem;
  height: 0.06rem;
  border: 2px solid #ffffff;
  border-radius: 100%;
  z-index: 10;
  color: #ffffff;
  -webkit-transition: border-color 0.2s ease-in-out, color 0.2s ease-in-out, -webkit-transform 0.4s ease-in-out;
  transition: border-color 0.2s ease-in-out, color 0.2s ease-in-out, -webkit-transform 0.4s ease-in-out;
  transition: border-color 0.2s ease-in-out, color 0.2s ease-in-out, transform 0.4s ease-in-out;
  transition: border-color 0.2s ease-in-out, color 0.2s ease-in-out, transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
}
@media (max-width: 767px) {
  .CommonVideoPopup-close {
    top: 0.01rem;
    right: 0.01rem;
    width: 0.035rem;
    height: 0.035rem;
  }
}
.isDesktop .CommonVideoPopup-close:hover {
  border-color: #ff0000;
  color: #ff0000;
  -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
          transform: rotate(360deg);
}
.isDesktop .CommonVideoPopup-close:active {
  -webkit-transform: scale(0.9);
      -ms-transform: scale(0.9);
          transform: scale(0.9);
}
.CommonVideoPopup-close svg {
  width: 0.025rem;
  height: 0.025rem;
  fill: currentColor;
}
@media (max-width: 767px) {
  .CommonVideoPopup-close svg {
    width: 0.012rem;
    height: 0.012rem;
  }
}
.CommonVideoPopup-inner {
  height: 100%;
  visibility: hidden;
}
.CommonVideoPopup .plyr__controls {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
@media (max-width: 767px) {
  .CommonVideoPopup .plyr__controls {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .CommonVideoPopup .plyr__controls .plyr__progress__container {
    margin-top: 0.003rem;
  }
}
.CommonVideoPopup .plyr__controls__item.plyr__time {
  width: 0.25rem;
  margin-left: 0;
  padding: 0 0.02rem !important;
  font-size: 0.072rem;
  line-height: 1;
  letter-spacing: -0.004rem;
}
@media (max-width: 767px) {
  .CommonVideoPopup .plyr__controls__item.plyr__time {
    width: 0.07rem;
    padding: 0 0.01rem !important;
    font-size: 0.02rem !important;
    line-height: 0.9 !important;
    letter-spacing: -0.002rem !important;
  }
}
.CommonVideoPopup .plyr__controls__item.plyr__time:last-child:before {
  display: none;
}
.CommonVideoPopup .plyr__controls input {
  cursor: none;
}
.CommonVideoPopup .plyr:-webkit-full-screen {
  cursor: default !important;
}
.CommonVideoPopup .plyr:-ms-fullscreen {
  cursor: default !important;
}
.CommonVideoPopup .plyr:fullscreen {
  cursor: default !important;
}
.CommonVideoPopup .plyr:-webkit-full-screen .plyr__controls {
  padding: 20px !important;
}
.CommonVideoPopup .plyr:-ms-fullscreen .plyr__controls {
  padding: 20px !important;
}
.CommonVideoPopup .plyr:fullscreen .plyr__controls {
  padding: 20px !important;
}
.CommonVideoPopup .plyr:-webkit-full-screen input {
  cursor: default !important;
}
.CommonVideoPopup .plyr:-ms-fullscreen input {
  cursor: default !important;
}
.CommonVideoPopup .plyr:fullscreen input {
  cursor: default !important;
}
