.u-creditsText {
  font-size: 0.016rem;
  line-height: 1.25;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .u-creditsText {
    font-size: 0.008rem;
    line-height: 1.875;
  }
}
.AboutCredits {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 767px) {
  .AboutCredits {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
}
.AboutCredits-inner {
  margin-bottom: 0.03rem;
}
@media (max-width: 767px) {
  .AboutCredits-inner {
    margin-bottom: 0.015rem;
  }
}
.AboutCredits-title {
  margin-bottom: 0.04rem;
  color: #ff0000;
  font-size: 0.072rem;
  line-height: 0.83333333;
  letter-spacing: -0.003rem;
  text-transform: uppercase;
  font-weight: 300;
  font-family: 'Begum', sans-serif;
}
@media (max-width: 767px) {
  .AboutCredits-title {
    margin-bottom: 0.01rem;
    font-size: 0.025rem;
    line-height: 1.2;
  }
}
.AboutCredits-listItem {
  font-size: 0.016rem;
  line-height: 1.25;
  text-transform: uppercase;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 0.025rem;
}
@media (max-width: 767px) {
  .AboutCredits-listItem {
    font-size: 0.008rem;
    line-height: 1.875;
  }
}
@media (max-width: 767px) {
  .AboutCredits-listItem {
    margin-bottom: 0.005rem;
  }
}
.AboutCredits-listItemPosition {
  width: 0.2rem;
  color: #ff0000;
  font-weight: 500;
  font-family: 'Begum', sans-serif;
}
@media (max-width: 767px) {
  .AboutCredits-listItemPosition {
    width: 0.15rem;
  }
}
.AboutCredits-listItemNames {
  width: 0.17rem;
}
@media (max-width: 767px) {
  .AboutCredits-listItemNames {
    width: 0.1rem;
  }
}
.AboutCredits-listItemNamesTitle {
  display: block;
  margin-top: -0.002rem;
  color: #ffffff;
  -webkit-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
}
.isDesktop .AboutCredits-listItemNamesTitle:hover {
  color: #ff0000;
}
.AboutCredits-period {
  font-size: 0.016rem;
  line-height: 1.25;
  text-transform: uppercase;
  width: 0.17rem;
  color: #ffffff;
}
@media (max-width: 767px) {
  .AboutCredits-period {
    font-size: 0.008rem;
    line-height: 1.875;
  }
}
@media (max-width: 767px) {
  .AboutCredits-period {
    width: 0.1rem;
  }
}
