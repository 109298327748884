*,
*:after,
*:before {
  outline: none;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
html {
  scrollbar-width: none;
}
html::-webkit-scrollbar {
  display: none;
}
body {
  background-color: #000000;
  overflow: hidden;
  -ms-scroll-chaining: none;
      overscroll-behavior: none;
  cursor: none;
  font-size: 0;
  font-weight: 400;
  font-family: 'PxGrotesk', sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "kern";
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern";
  -moz-font-feature-settings: "kern=1";
  -moz-font-feature-settings: "kern";
  -ms-font-feature-settings: "kern" 1;
}
body.enabledScroll {
  overflow: auto;
}
a {
  cursor: none;
  text-decoration: none;
}
button {
  outline: none;
}
::-moz-selection {
  background-color: #ff0000;
  color: #ffffff;
}
::selection {
  background-color: #ff0000;
  color: #ffffff;
}
.Cursor {
  position: fixed;
  display: none;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 0.06rem;
  height: 0.06rem;
  border: 2px solid #ff0000;
  border-radius: 100%;
  z-index: 9999;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
  -webkit-transform: scale(0.5);
      -ms-transform: scale(0.5);
          transform: scale(0.5);
  -webkit-transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out, transform 0.2s ease-in-out;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out, transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}
.isDesktop .Cursor {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.Cursor.isDefault {
  border-color: #000000;
  background-color: #000000;
  -webkit-transform: scale(0.2);
      -ms-transform: scale(0.2);
          transform: scale(0.2);
}
.Cursor.isRedZone {
  border-color: #000000;
}
.Cursor.isRedZone svg {
  fill: #000000;
}
.Cursor.isIncrease {
  -webkit-transform: scale(0.7);
      -ms-transform: scale(0.7);
          transform: scale(0.7);
}
.Cursor.isIncrease.isPressed {
  -webkit-transform: scale(0.6);
      -ms-transform: scale(0.6);
          transform: scale(0.6);
}
.Cursor.isPlay,
.Cursor.isPause,
.Cursor.isZoom,
.Cursor.isClose,
.Cursor.isNext,
.Cursor.isMemories {
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
}
.Cursor.isPlay.isPressed,
.Cursor.isPause.isPressed,
.Cursor.isZoom.isPressed,
.Cursor.isClose.isPressed,
.Cursor.isNext.isPressed,
.Cursor.isMemories.isPressed {
  -webkit-transform: scale(0.9);
      -ms-transform: scale(0.9);
          transform: scale(0.9);
}
.Cursor.isPlay .Cursor-iconPlay,
.Cursor.isPause .Cursor-iconPause,
.Cursor.isZoom .Cursor-iconZoom,
.Cursor.isClose .Cursor-iconClose,
.Cursor.isNext .Cursor-iconNext,
.Cursor.isMemories .Cursor-iconMemories {
  opacity: 1;
  -webkit-transform: scale(1) rotate(360deg);
      -ms-transform: scale(1) rotate(360deg);
          transform: scale(1) rotate(360deg);
}
.Cursor-icon {
  position: absolute;
  opacity: 0;
  -webkit-transform: scale(0) rotate(0deg);
      -ms-transform: scale(0) rotate(0deg);
          transform: scale(0) rotate(0deg);
  -webkit-transition: opacity 0.2s ease-in-out, -webkit-transform 0.4s ease-in-out;
  transition: opacity 0.2s ease-in-out, -webkit-transform 0.4s ease-in-out;
  transition: opacity 0.2s ease-in-out, transform 0.4s ease-in-out;
  transition: opacity 0.2s ease-in-out, transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
}
.Cursor-iconPlay svg {
  width: 0.025rem;
  height: 0.024rem;
}
.Cursor-iconPause svg {
  width: 0.016rem;
  height: 0.02rem;
}
.Cursor-iconZoom svg {
  width: 0.032rem;
  height: 0.032rem;
}
.Cursor-iconClose svg {
  width: 0.026rem;
  height: 0.026rem;
}
.Cursor-iconNext svg {
  width: 0.03rem;
  height: 0.027rem;
}
.Cursor-iconMemories svg {
  width: 0.029rem;
  height: 0.025rem;
}
.Cursor-icon svg {
  fill: #ff0000;
  -webkit-transition: fill 0.2s ease-in-out;
  transition: fill 0.2s ease-in-out;
}
.HorizontalPage {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  scrollbar-width: none;
}
.isTablet .HorizontalPage,
.isMobile .HorizontalPage {
  right: 0;
  overflow-x: scroll;
}
.isTablet .HorizontalPage.disabledScroll,
.isMobile .HorizontalPage.disabledScroll {
  overflow: hidden;
}
.HorizontalPage::-webkit-scrollbar {
  display: none;
}
.Orientation-alert {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0.015rem;
  background-color: #141017;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
}
.Orientation-alert.isShow {
  opacity: 1;
  visibility: visible;
}
.Orientation-alertIcon {
  width: 0.12rem;
  height: 0.18rem;
  margin-bottom: 0.025rem;
}
.Orientation-alertIcon svg {
  width: 100%;
  height: 100%;
}
.Orientation-alertTitle {
  max-width: 0.48rem;
  color: #ffffff;
  font-size: 0.03rem;
  line-height: 1;
  text-transform: uppercase;
  text-align: center;
}
.Loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ff0000;
  z-index: 4000;
}
.Loader-bar {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0%;
  height: 100%;
  background-color: #141017;
}
@media (max-width: 767px) {
  .Loader-bar {
    width: 100%;
    height: 0%;
  }
}
.Loader-inner {
  position: absolute;
  left: 0.03rem;
  bottom: 0.05rem;
  z-index: 100;
  color: #ffffff;
}
@media (max-width: 767px) {
  .Loader-inner {
    left: 0.02rem;
    bottom: 0.02rem;
  }
}
.Loader-title {
  font-size: 0.016rem;
  line-height: 1.25;
  text-transform: uppercase;
  font-weight: 700;
}
@media (max-width: 767px) {
  .Loader-title {
    font-size: 0.01rem;
    line-height: 2;
  }
}
.Loader-percentage {
  margin-bottom: 0.06rem;
  font-size: 0.32rem;
  line-height: 0.775;
  letter-spacing: -0.028rem;
}
@media (max-width: 767px) {
  .Loader-percentage {
    margin-bottom: 0.02rem;
    font-size: 0.12rem;
    line-height: 0.83333333;
    letter-spacing: -0.01rem;
  }
}
.Loader-text {
  font-size: 0.016rem;
  line-height: 1.25;
  text-transform: uppercase;
  width: 0.48rem;
  font-weight: 500;
  font-family: 'Begum', sans-serif;
}
@media (max-width: 767px) {
  .Loader-text {
    width: 0.18rem;
    font-size: 0.008rem;
    line-height: 1.25;
  }
}
.PageSection {
  -webkit-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
}
.PageSection.isShow {
  opacity: 0;
}
.overflowLock {
  overflow: hidden !important;
}
.Content.isLandscape,
.Content.isPortrait {
  display: none;
}
.NeedFixOnPopup {
  top: 0;
  min-width: 100%;
  z-index: 9999;
  will-change: top;
}
.isMobile .NeedFixOnPopup {
  min-width: 100%;
}
.FixedOnPopup {
  position: fixed !important;
  left: 0 !important;
  width: 100% !important;
}
