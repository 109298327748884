.CommonVideo-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0.7;
}
.CommonVideo-background video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  display: none;
}
.isDesktop .CommonVideo-background video {
  display: block;
}
.CommonVideo-background img {
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  width: 100%;
  height: 100%;
}
.isDesktop .CommonVideo-background img {
  display: none;
}
