.MemoriesPopup-resources {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 0.48rem;
  padding: 0.03rem 0.04rem 0.04rem;
  border-radius: 0.02rem;
  background-color: #ff0000;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .MemoriesPopup-resources {
    width: 0.4rem;
    padding: 0.03rem 0.02rem;
  }
}
.MemoriesPopup-resourcesHeader {
  color: #000000;
  font-size: 0.06rem;
  line-height: 0.91666667;
  letter-spacing: -0.004rem;
}
@media (max-width: 767px) {
  .MemoriesPopup-resourcesHeader {
    font-size: 0.036rem;
    line-height: 0.83333333;
    letter-spacing: -0.002rem;
  }
}
.MemoriesPopup-resourcesCaption {
  color: #ffffff;
}
.MemoriesPopup-resourcesListItem {
  margin-bottom: 0.03rem;
  padding-top: 0.023rem;
  position: relative;
}
@media (max-width: 767px) {
  .MemoriesPopup-resourcesListItem {
    margin-bottom: 0.02rem;
    padding-top: 0.015rem;
  }
}
.MemoriesPopup-resourcesListItem:last-child {
  margin-bottom: 0;
}
.MemoriesPopup-resourcesListItemLine {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ffffff;
}
.MemoriesPopup-resourcesListItemTitle {
  color: #ffffff;
  font-size: 0.016rem;
  line-height: 1.25;
  font-weight: 700;
}
@media (max-width: 767px) {
  .MemoriesPopup-resourcesListItemTitle {
    font-size: 0.008rem;
    line-height: 1.25;
  }
}
.MemoriesPopup-resourcesListItemValue {
  margin-top: -0.03rem;
  color: #000000;
  font-size: 0.228rem;
  line-height: 1;
  letter-spacing: -0.016rem;
}
@media (max-width: 767px) {
  .MemoriesPopup-resourcesListItemValue {
    margin-top: -0.02rem;
    font-size: 0.15rem;
  }
}
.MemoriesPopup-resourcesText {
  color: #000000;
  font-size: 0.016rem;
  line-height: 1.25;
  font-weight: 700;
}
@media (max-width: 767px) {
  .MemoriesPopup-resourcesText {
    font-size: 0.008rem;
    line-height: 1.25;
  }
}
