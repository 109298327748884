.MemoriesPopup-gallery {
  height: 100%;
  border-radius: 0.02rem;
  overflow: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
}
.isDesktop .MemoriesPopup-gallery.MemoriesPopup-galleryZoomOpen:hover .MemoriesPopup-galleryItemInner {
  -webkit-transform: scale(1.05);
      -ms-transform: scale(1.05);
          transform: scale(1.05);
}
.MemoriesPopup-gallery .slick-slide {
  -webkit-transition: opacity 0.5s ease-in-out 0s !important;
  transition: opacity 0.5s ease-in-out 0s !important;
}
.MemoriesPopup-galleryWrapper {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 1.12rem;
  position: relative;
  background-color: #000000;
}
@media (max-width: 767px) {
  .MemoriesPopup-galleryWrapper {
    width: 0.56rem;
  }
}
.MemoriesPopup-galleryWrapper.isLargeSize {
  width: 2.08rem;
}
@media (max-width: 767px) {
  .MemoriesPopup-galleryWrapper.isLargeSize {
    width: 1.04rem;
  }
}
.MemoriesPopup-galleryWrapper.isMediumSize {
  width: 1.6rem;
}
@media (max-width: 767px) {
  .MemoriesPopup-galleryWrapper.isMediumSize {
    width: 0.8rem;
  }
}
.MemoriesPopup-galleryWrapper.isSmallSize {
  width: 0.96rem;
}
@media (max-width: 767px) {
  .MemoriesPopup-galleryWrapper.isSmallSize {
    width: 0.48rem;
  }
}
.MemoriesPopup-galleryWrapper.isBlackTheme .MemoriesPopup-galleryCaption,
.MemoriesPopup-galleryWrapper.isBlackTheme .MemoriesPopup-galleryFile {
  color: #000000;
}
.MemoriesPopup-galleryWrapper.isCentered .MemoriesPopup-galleryItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 0.04rem;
}
@media (max-width: 767px) {
  .MemoriesPopup-galleryWrapper.isCentered .MemoriesPopup-galleryItem {
    padding: 0 0.02rem;
  }
}
.MemoriesPopup-galleryWrapper.isCentered .MemoriesPopup-galleryItemInner {
  width: 100%;
  height: 0.52rem;
  margin-top: -0.05rem;
  -webkit-box-shadow: 0 20px 80px rgba(0, 0, 0, 0.5);
          box-shadow: 0 20px 80px rgba(0, 0, 0, 0.5);
}
@media (max-width: 767px) {
  .MemoriesPopup-galleryWrapper.isCentered .MemoriesPopup-galleryItemInner {
    height: 0.26rem;
    margin-top: -0.025rem;
  }
}
.MemoriesPopup-galleryWrapper.isVideoGallery .MemoriesPopup-galleryItem img {
  -o-object-fit: cover;
     object-fit: cover;
}
.isDesktop .MemoriesPopup-galleryWrapper.isVideoGallery .MemoriesPopup-galleryItem img {
  display: none;
}
.MemoriesPopup-galleryWrapper .slick-track,
.MemoriesPopup-galleryWrapper .slick-list {
  height: 100%;
}
.MemoriesPopup-galleryOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.isDesktop .MemoriesPopup-galleryOverlay {
  display: none;
}
.MemoriesPopup-galleryItem {
  height: 100%;
}
.isDesktop .MemoriesPopup-galleryItem.isBackgroundGrid {
  background-size: 0.08rem 0.08rem, 0 0, 0.04rem 0.04rem, 0.04rem 0.04rem !important;
}
.MemoriesPopup-galleryItemInner {
  height: 100%;
}
.MemoriesPopup-galleryItemInner.isTypeCover img {
  -o-object-fit: cover;
     object-fit: cover;
}
.MemoriesPopup-galleryItemInner img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
}
.MemoriesPopup-galleryItemInner video {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.isTablet .MemoriesPopup-galleryItemInner video,
.isMobile .MemoriesPopup-galleryItemInner video {
  display: none;
}
.MemoriesPopup-galleryFooter {
  position: absolute;
  left: 0.04rem;
  right: 0.04rem;
  bottom: 0.04rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  pointer-events: none;
}
@media (max-width: 767px) {
  .MemoriesPopup-galleryFooter {
    left: 0.02rem;
    right: 0.02rem;
    bottom: 0.02rem;
  }
}
.MemoriesPopup-galleryInfo {
  pointer-events: all;
  text-transform: uppercase;
}
.MemoriesPopup-galleryInfoInner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.MemoriesPopup-galleryTitle,
.MemoriesPopup-galleryCaption {
  font-size: 0.06rem;
  line-height: 0.91666667;
}
@media (max-width: 767px) {
  .MemoriesPopup-galleryTitle,
  .MemoriesPopup-galleryCaption {
    font-size: 0.036rem;
    line-height: 0.83333333;
    letter-spacing: -0.002rem;
  }
}
.MemoriesPopup-galleryTitle div,
.MemoriesPopup-galleryCaption div {
  display: inline-block;
}
.MemoriesPopup-galleryTitle {
  margin-bottom: -0.01rem;
  color: #ff0000;
  letter-spacing: -0.003rem;
  font-weight: 300;
  font-family: 'Begum', sans-serif;
}
@media (max-width: 767px) {
  .MemoriesPopup-galleryTitle {
    margin-bottom: -0.005rem;
  }
}
.MemoriesPopup-galleryCaption {
  color: #ffffff;
  letter-spacing: -0.004rem;
}
.MemoriesPopup-galleryFile {
  margin: 0 0 -0.002rem 0.016rem;
  color: #ffffff;
  font-size: 0.016rem;
  line-height: 1.25;
  white-space: nowrap;
  font-weight: 700;
}
@media (max-width: 767px) {
  .MemoriesPopup-galleryFile {
    margin: 0 0 -0.002rem 0.01rem;
    font-size: 0.008rem;
    line-height: 1.25;
  }
}
.MemoriesPopup-galleryNav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  pointer-events: all;
}
.MemoriesPopup-galleryNavPrev,
.MemoriesPopup-galleryNavNext {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 0.06rem;
  height: 0.06rem;
  border: 2px solid #ff0000;
  border-radius: 100%;
  -webkit-transition: background-color 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}
@media (max-width: 767px) {
  .MemoriesPopup-galleryNavPrev,
  .MemoriesPopup-galleryNavNext {
    width: 0.04rem;
    height: 0.04rem;
  }
}
.isDesktop .MemoriesPopup-galleryNavPrev:hover,
.isDesktop .MemoriesPopup-galleryNavNext:hover {
  background-color: #ff0000;
}
.isDesktop .MemoriesPopup-galleryNavPrev:hover svg,
.isDesktop .MemoriesPopup-galleryNavNext:hover svg {
  fill: #000000;
}
.isDesktop .MemoriesPopup-galleryNavPrev:active,
.isDesktop .MemoriesPopup-galleryNavNext:active {
  -webkit-transform: scale(0.9);
      -ms-transform: scale(0.9);
          transform: scale(0.9);
}
.MemoriesPopup-galleryNavPrev svg,
.MemoriesPopup-galleryNavNext svg {
  width: 0.03rem;
  height: 0.025rem;
  fill: #ff0000;
  -webkit-transition: fill 0.2s ease-in-out;
  transition: fill 0.2s ease-in-out;
}
@media (max-width: 767px) {
  .MemoriesPopup-galleryNavPrev svg,
  .MemoriesPopup-galleryNavNext svg {
    width: 0.025rem;
    height: 0.015rem;
  }
}
.MemoriesPopup-galleryNavPrev {
  margin-right: 0.02rem;
}
@media (max-width: 767px) {
  .MemoriesPopup-galleryNavPrev {
    margin-right: 0.01rem;
  }
}
.MemoriesPopup-galleryNavPrev svg {
  -webkit-transform: rotate(-180deg);
      -ms-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.MemoriesPopup-galleryZoom {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  display: none;
  width: 100vw;
  background-color: #000000;
  z-index: 20;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
}
.isDesktop .MemoriesPopup-galleryZoom {
  display: block;
}
.MemoriesPopup-galleryZoom.isOpen {
  opacity: 1;
  visibility: visible;
}
.MemoriesPopup-galleryZoomClose {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.isTablet .MemoriesPopup-galleryZoomClose,
.isMobile .MemoriesPopup-galleryZoomClose {
  top: 0.06rem;
  left: calc(100% - 0.001rem * 120);
  right: inherit;
  bottom: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 0.06rem;
  height: 0.06rem;
  background-color: #ffffff;
  border-radius: 100%;
}
@media (max-width: 767px) {
  .isTablet .MemoriesPopup-galleryZoomClose,
  .isMobile .MemoriesPopup-galleryZoomClose {
    top: 0.02rem;
    left: calc(100% - 0.001rem * 50);
    width: 0.04rem;
    height: 0.04rem;
  }
}
.MemoriesPopup-galleryZoomClose svg {
  display: none;
  height: 0.025rem;
  fill: #000000;
}
.isTablet .MemoriesPopup-galleryZoomClose svg,
.isMobile .MemoriesPopup-galleryZoomClose svg {
  display: block;
}
@media (max-width: 767px) {
  .MemoriesPopup-galleryZoomClose svg {
    height: 0.015rem;
  }
}
.MemoriesPopup-galleryZoomInner {
  height: 100%;
}
.MemoriesPopup-galleryZoomItemInner {
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
}
.isTablet .MemoriesPopup-galleryZoomItemInner,
.isMobile .MemoriesPopup-galleryZoomItemInner {
  background-size: contain;
}
.MemoriesPopup-galleryZoomNav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  right: 0.04rem;
  bottom: 0.04rem;
}
.MemoriesPopup-galleryZoomNavPrev,
.MemoriesPopup-galleryZoomNavNext {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 0.06rem;
  height: 0.06rem;
  border: 2px solid #ff0000;
  border-radius: 100%;
  -webkit-transition: background-color 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}
@media (max-width: 767px) {
  .MemoriesPopup-galleryZoomNavPrev,
  .MemoriesPopup-galleryZoomNavNext {
    width: 0.04rem;
    height: 0.04rem;
  }
}
.isDesktop .MemoriesPopup-galleryZoomNavPrev:hover,
.isDesktop .MemoriesPopup-galleryZoomNavNext:hover {
  background-color: #ff0000;
}
.isDesktop .MemoriesPopup-galleryZoomNavPrev:hover svg,
.isDesktop .MemoriesPopup-galleryZoomNavNext:hover svg {
  fill: #000000;
}
.isDesktop .MemoriesPopup-galleryZoomNavPrev:active,
.isDesktop .MemoriesPopup-galleryZoomNavNext:active {
  -webkit-transform: scale(0.9);
      -ms-transform: scale(0.9);
          transform: scale(0.9);
}
.MemoriesPopup-galleryZoomNavPrev svg,
.MemoriesPopup-galleryZoomNavNext svg {
  width: 0.03rem;
  height: 0.025rem;
  fill: #ff0000;
  -webkit-transition: fill 0.2s ease-in-out;
  transition: fill 0.2s ease-in-out;
}
@media (max-width: 767px) {
  .MemoriesPopup-galleryZoomNavPrev svg,
  .MemoriesPopup-galleryZoomNavNext svg {
    width: 0.025rem;
    height: 0.015rem;
  }
}
.MemoriesPopup-galleryZoomNavPrev {
  margin-right: 0.02rem;
}
@media (max-width: 767px) {
  .MemoriesPopup-galleryZoomNavPrev {
    margin-right: 0.01rem;
  }
}
.MemoriesPopup-galleryZoomNavPrev svg {
  -webkit-transform: rotate(-180deg);
      -ms-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
@media (max-width: 767px) {
  .MemoriesPopup-galleryZoomNav {
    right: 0.02rem;
    bottom: 0.02rem;
  }
}
