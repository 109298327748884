.MemoriesSwitch {
  position: fixed;
  right: 0.06rem;
  bottom: 0.08rem;
  -webkit-transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
}
@media (max-width: 767px) {
  .MemoriesSwitch {
    display: none;
  }
}
.MemoriesSwitch.isHidden {
  opacity: 0;
  visibility: hidden;
}
.MemoriesSwitch-item {
  display: block;
  width: 0.04rem;
  height: 0.04rem;
}
.MemoriesSwitch-item:first-child {
  height: 0.044rem;
  margin-bottom: 0.036rem;
}
.isDesktop .MemoriesSwitch-item:hover svg,
.MemoriesSwitch-item.isActive svg {
  stroke: #ff0000;
}
.MemoriesSwitch-item.isActive,
.MemoriesSwitch-item.isDisabled {
  pointer-events: none;
}
.MemoriesSwitch-item svg {
  width: 100%;
  height: 100%;
  stroke: #ffffff;
  -webkit-transition: stroke 0.2s ease-in-out;
  transition: stroke 0.2s ease-in-out;
}
