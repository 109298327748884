.MemoriesPopup-film {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 1.28rem;
  border-radius: 0.02rem;
  position: relative;
  overflow: hidden;
  background-color: #000000;
}
@media (max-width: 767px) {
  .MemoriesPopup-film {
    width: 0.64rem;
  }
}
.MemoriesPopup-filmVideo {
  height: 100%;
}
.MemoriesPopup-filmVideo video,
.MemoriesPopup-filmVideo img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.isTablet .MemoriesPopup-filmVideo video,
.isMobile .MemoriesPopup-filmVideo video {
  display: none;
}
.isDesktop .MemoriesPopup-filmVideo img {
  display: none;
}
.MemoriesPopup-filmFooter {
  position: absolute;
  left: 0.04rem;
  right: 0.04rem;
  bottom: 0.04rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
@media (max-width: 767px) {
  .MemoriesPopup-filmFooter {
    left: 0.02rem;
    right: 0.02rem;
    bottom: 0.02rem;
  }
}
.MemoriesPopup-filmFooterInner {
  text-transform: uppercase;
}
.MemoriesPopup-filmTitle,
.MemoriesPopup-filmCaption {
  font-size: 0.06rem;
  line-height: 0.91666667;
}
@media (max-width: 767px) {
  .MemoriesPopup-filmTitle,
  .MemoriesPopup-filmCaption {
    font-size: 0.036rem;
    line-height: 0.83333333;
    letter-spacing: -0.002rem;
  }
}
.MemoriesPopup-filmTitle div,
.MemoriesPopup-filmCaption div {
  display: inline-block;
}
.MemoriesPopup-filmTitle {
  margin-bottom: -0.01rem;
  color: #ff0000;
  letter-spacing: -0.003rem;
  font-weight: 300;
  font-family: 'Begum', sans-serif;
}
@media (max-width: 767px) {
  .MemoriesPopup-filmTitle {
    margin-bottom: -0.005rem;
  }
}
.MemoriesPopup-filmCaption {
  color: #ffffff;
  letter-spacing: -0.004rem;
}
.MemoriesPopup-filmFile {
  margin: 0 0 -0.002rem 0.016rem;
  color: #ffffff;
  font-size: 0.016rem;
  line-height: 1.25;
  font-weight: 700;
}
@media (max-width: 767px) {
  .MemoriesPopup-filmFile {
    margin: 0 0 -0.002rem 0.01rem;
    font-size: 0.008rem;
    line-height: 1.25;
  }
}
.MemoriesPopup-filmButton {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  border: 2px solid #ff0000;
  border-radius: 0.05rem;
  background-color: transparent;
  color: #ff0000;
  font-size: 0.032rem;
  line-height: 0.9375;
  letter-spacing: -1px;
  text-transform: uppercase;
  -webkit-transform-origin: 50% 100%;
      -ms-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, transform 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  padding: 0.016rem 0 0.02rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 0.4rem;
}
.isDesktop .MemoriesPopup-filmButton:hover {
  background-color: #ff0000;
  color: #ffffff;
}
.isDesktop .MemoriesPopup-filmButton:hover span {
  -webkit-animation: shift-text 0.5s linear;
          animation: shift-text 0.5s linear;
}
.isDesktop .MemoriesPopup-filmButton:active {
  -webkit-transform: scale(0.9);
      -ms-transform: scale(0.9);
          transform: scale(0.9);
}
.MemoriesPopup-filmButton span {
  -webkit-transform-origin: 50% 50%;
      -ms-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
          transform: translateY(0);
}
@-webkit-keyframes shift-text {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  45% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  55% {
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes shift-text {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  45% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  55% {
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@media (max-width: 767px) {
  .MemoriesPopup-filmButton {
    padding: 0.005rem 0 0.007rem;
    font-size: 0.014rem;
    line-height: 1.42857143;
  }
}
@media (max-width: 767px) {
  .MemoriesPopup-filmButton {
    width: 0.2rem;
  }
}
