.MemoriesPopup-video {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 0.85rem;
  padding: 0 0.04rem;
  border-radius: 0.02rem;
  position: relative;
  overflow: hidden;
}
.isDesktop .MemoriesPopup-video {
  background-size: 0.08rem 0.08rem, 0 0, 0.04rem 0.04rem, 0.04rem 0.04rem !important;
}
@media (max-width: 767px) {
  .MemoriesPopup-video {
    width: 0.64rem;
    padding: 0 0.02rem;
  }
}
.MemoriesPopup-video.isLargeSize {
  width: 1.14rem;
}
@media (max-width: 767px) {
  .MemoriesPopup-video.isLargeSize {
    width: 0.55rem;
  }
}
.MemoriesPopup-videoCover {
  width: 100%;
  margin-top: -0.05rem;
}
@media (max-width: 767px) {
  .MemoriesPopup-videoCover {
    margin-top: 0;
  }
}
.MemoriesPopup-videoFooter {
  position: absolute;
  left: 0.04rem;
  right: 0.04rem;
  bottom: 0.04rem;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .MemoriesPopup-videoFooter {
    left: 0.02rem;
    right: 0.02rem;
    bottom: 0.02rem;
  }
}
.MemoriesPopup-videoFooterInner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.MemoriesPopup-videoTitle,
.MemoriesPopup-videoCaption {
  font-size: 0.06rem;
  line-height: 0.91666667;
}
@media (max-width: 767px) {
  .MemoriesPopup-videoTitle,
  .MemoriesPopup-videoCaption {
    font-size: 0.036rem;
    line-height: 0.83333333;
    letter-spacing: -0.002rem;
  }
}
.MemoriesPopup-videoTitle div,
.MemoriesPopup-videoCaption div {
  display: inline-block;
}
.MemoriesPopup-videoTitle {
  margin-bottom: -0.01rem;
  color: #ff0000;
  letter-spacing: -0.003rem;
  font-weight: 300;
  font-family: 'Begum', sans-serif;
}
@media (max-width: 767px) {
  .MemoriesPopup-videoTitle {
    margin-bottom: -0.005rem;
  }
}
.MemoriesPopup-videoCaption {
  color: #ffffff;
  letter-spacing: -0.004rem;
}
.MemoriesPopup-videoFile {
  margin: 0 0 -0.002rem 0.016rem;
  color: #ffffff;
  font-size: 0.016rem;
  line-height: 1.25;
  font-weight: 700;
}
@media (max-width: 767px) {
  .MemoriesPopup-videoFile {
    margin: 0 0 -0.002rem 0.01rem;
    font-size: 0.008rem;
    line-height: 1.25;
  }
}
