.MemoriesPopup-acknowledgements {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  padding: 0.04rem;
  border-radius: 0.02rem;
  position: relative;
  overflow: hidden;
  background: radial-gradient(transparent 3px, #111619 3px), radial-gradient(transparent 3px, #111619 3px), linear-gradient(rgba(255, 255, 255, 0.2) 1px, transparent 1px) 0 -0.5px, linear-gradient(90deg, rgba(255, 255, 255, 0.2) 1px, transparent 1px) -0.5px 0;
  background-color: #111619;
}
.isDesktop .MemoriesPopup-acknowledgements {
  background-size: 0.08rem 0.08rem, 0 0, 0.04rem 0.04rem, 0.04rem 0.04rem;
}
@media (max-width: 767px) {
  .MemoriesPopup-acknowledgements {
    padding: 0.02rem;
  }
}
.MemoriesPopup-acknowledgementsTitle {
  color: #ff0000;
  font-size: 0.06rem;
  line-height: 0.91666667;
  letter-spacing: -0.003rem;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .MemoriesPopup-acknowledgementsTitle {
    font-size: 0.036rem;
    line-height: 0.83333333;
    letter-spacing: -0.002rem;
  }
}
.MemoriesPopup-acknowledgementsTitle div {
  display: inline-block;
}
.MemoriesPopup-acknowledgementsList {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.MemoriesPopup-acknowledgementsListColumn {
  width: 0.42rem;
  margin-right: 0.08rem;
}
@media (max-width: 767px) {
  .MemoriesPopup-acknowledgementsListColumn {
    width: 0.21rem;
    margin-right: 0.04rem;
  }
}
.MemoriesPopup-acknowledgementsListColumn:last-child {
  margin-right: 0;
}
.MemoriesPopup-acknowledgementsListItem {
  margin-bottom: 0.03rem;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .MemoriesPopup-acknowledgementsListItem {
    margin-bottom: 0.02rem;
  }
}
.MemoriesPopup-acknowledgementsListItem:last-child {
  margin-bottom: 0;
}
.MemoriesPopup-acknowledgementsListItemName,
.MemoriesPopup-acknowledgementsListItemText {
  color: #ff0000;
  font-size: 0.016rem;
  line-height: 1.25;
  font-weight: 700;
}
@media (max-width: 767px) {
  .MemoriesPopup-acknowledgementsListItemName,
  .MemoriesPopup-acknowledgementsListItemText {
    font-size: 0.008rem;
    line-height: 1.25;
  }
}
.MemoriesPopup-acknowledgementsListItemName {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-bottom: 0.005rem;
  -webkit-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
}
.isDesktop .MemoriesPopup-acknowledgementsListItemName:hover {
  color: #ffffff;
}
.MemoriesPopup-acknowledgementsListItemText {
  color: #ffffff;
}
.MemoriesPopup-acknowledgementsOwner {
  color: #ffffff;
  font-size: 0.016rem;
  line-height: 1.25;
  font-weight: 700;
  position: absolute;
  right: 0.04rem;
  bottom: 0.04rem;
  text-transform: uppercase;
  font-family: 'Begum', sans-serif;
}
@media (max-width: 767px) {
  .MemoriesPopup-acknowledgementsOwner {
    font-size: 0.008rem;
    line-height: 1.25;
  }
}
@media (max-width: 767px) {
  .MemoriesPopup-acknowledgementsOwner {
    right: 0.02rem;
    bottom: 0.02rem;
  }
}
