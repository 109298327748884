.MemoriesPopup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
}
.MemoriesPopup-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}
.MemoriesPopup-inner {
  width: 100%;
  height: 100%;
}
.MemoriesPopup .HorizontalPage {
  padding: 0.02rem 0 0.02rem 0.02rem;
}
@media (max-width: 767px) {
  .MemoriesPopup .HorizontalPage {
    padding: 0.01rem 0 0.01rem 0.01rem;
  }
}
.disabledHover .MemoriesPopup .HorizontalPage {
  pointer-events: none;
}
.MemoriesPopup-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.MemoriesPopup-close {
  position: fixed;
  top: 0.06rem;
  right: 0.06rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 0.06rem;
  height: 0.06rem;
  border: 1px solid #ffffff;
  border-radius: 100%;
  background-color: #000000;
  z-index: 2000;
  -webkit-transform-origin: 50% 50%;
      -ms-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  -webkit-transition: background-color 0.2s ease-in-out, -webkit-transform 0.4s ease-in-out;
  transition: background-color 0.2s ease-in-out, -webkit-transform 0.4s ease-in-out;
  transition: background-color 0.2s ease-in-out, transform 0.4s ease-in-out;
  transition: background-color 0.2s ease-in-out, transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
}
@media (max-width: 767px) {
  .MemoriesPopup-close {
    top: 0.02rem;
    right: 0.01rem;
    width: 0.04rem;
    height: 0.04rem;
  }
}
.isDesktop .MemoriesPopup-close:hover {
  background-color: #ff0000;
  -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
          transform: rotate(360deg);
}
.isDesktop .MemoriesPopup-close:active {
  -webkit-transform: scale(0.9) translateZ(0);
          transform: scale(0.9) translateZ(0);
}
.MemoriesPopup-close svg {
  height: 0.025rem;
  fill: #ffffff;
}
@media (max-width: 767px) {
  .MemoriesPopup-close svg {
    height: 0.015rem;
  }
}
.MemoriesPopup-end {
  width: 0.02rem;
  height: 100%;
}
@media (max-width: 767px) {
  .MemoriesPopup-end {
    width: 0.01rem;
  }
}
.MemoriesPopup-hint {
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}
.MemoriesPopup-hint.isHidden {
  opacity: 0;
}
.MemoriesPopup-hintWrapper {
  position: absolute;
  top: 50%;
  right: 0.015rem;
  display: none;
  margin-top: -0.013rem;
}
@media (max-width: 767px) {
  .MemoriesPopup-hintWrapper {
    display: block;
  }
}
.MemoriesPopup-hint svg {
  width: 0.03rem;
  height: 0.026rem;
  fill: #000000;
  -webkit-animation: arrow 1s ease-in-out infinite;
          animation: arrow 1s ease-in-out infinite;
}
@-webkit-keyframes arrow {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@keyframes arrow {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
