.AboutIntro {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.AboutIntro-title {
  color: #ffffff;
  font-size: 0.072rem;
  line-height: 0.83333333;
  letter-spacing: -0.003rem;
  text-transform: uppercase;
  font-weight: 300;
  font-family: 'Begum', sans-serif;
}
@media (max-width: 767px) {
  .AboutIntro-title {
    margin-bottom: 0.015rem;
    font-size: 0.025rem;
    line-height: 1.2;
  }
}
.AboutIntro-title:first-letter {
  margin-left: 0.08rem;
}
@media (max-width: 767px) {
  .AboutIntro-title:first-letter {
    margin-left: 0.03rem;
  }
}
.AboutIntro-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 0.72rem;
  font-size: 0.016rem;
  line-height: 1.25;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .AboutIntro-inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    width: 0.3rem;
    font-size: 0.008rem;
    line-height: 1.875;
  }
}
.AboutIntro-disclaimer {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  margin-right: 0.055rem;
  color: #ff0000;
}
@media (max-width: 767px) {
  .AboutIntro-disclaimer {
    margin: 0 0 0.01rem;
  }
}
.AboutIntro-text {
  color: #ffffff;
}
